import api from '../../../../api'

export const FETCH_STOPPING_HISTORY_PENDING = 'FETCH_STOPPING_HISTORY_PENDING'
export const FETCH_STOPPING_HISTORY_FULFILLED = 'FETCH_STOPPING_HISTORY_FULFILLED'
export const FETCH_STOPPING_HISTORY_REJECTED = 'FETCH_STOPPING_HISTORY_REJECTED'

export const CHANGE_STOPPING_HISTORY = 'CHANGE_STOPPING_HISTORY'

export function changeStoppingHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_STOPPING_HISTORY, payload: { name, value } })
  }
}

export function findAllStoppingHistorys(filter) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_STOPPING_HISTORY_PENDING });
    api({
      method: 'post',
      data: {
        query: `
                query findAllStoppingHistorys {
                  reportStoppingPoints(
                    veiId: ${filter.veiculo} 
                    parDhi: "${filter.dataHoraInicial}"
                    parDhf: "${filter.dataHoraFinal}") {
                      parLcl
                      parDst
                      parIgn
                      parDep
                      parTep
                      parVlm
                      parHin
                      parHfn
                      parTdp
                      parDtd
                      parLat
                      parLng
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_STOPPING_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { reportStoppingPoints } = data
        dispatch({ type: FETCH_STOPPING_HISTORY_FULFILLED, payload: reportStoppingPoints });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_STOPPING_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}