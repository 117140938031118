import React from "react";
import classnames from "classnames";

import { JarvisWidget } from "../../widgets";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as TodoActions from "../TodoActions";

import TodoList from "./TodoList";
import TodoForm from "./TodoForm";

class TodoWidget extends React.Component {
  render() {
    return (
      <JarvisWidget
        deletebutton={false}
        togglebutton={false}
        editbutton={false}
        editbutton={false}
        color="blue"
        id="todo-widget">

        <header>
          <span className="widget-icon">
            <i className="fa fa-check txt-color-white" />
          </span>

          <span style={{ marginLeft: 10 }}><h2>Chamados</h2></span>

          <div className="widget-toolbar">

            <button
              className={classnames([
                "btn btn-xs btn-default",
                {
                  active: this.props.newTodo
                }
              ])}
              onClick={this.props.toggleNewTodo}
            >
              <i
                className={classnames({
                  "fa fa-plus": !this.props.newTodo,
                  "fa fa-times": this.props.newTodo
                })} style={{ paddingRight: 5 }}
              />
              Novo Chamado
            </button>
          </div>
        </header>
        <div>
          <div className="widget-body no-padding smart-form">
            <div
              className={classnames({
                hidden: !this.props.newTodo
              })}
            >
              <h5 className="todo-group-title">
                <i className="fa fa-plus-circle" /> Detalhes do Chamado
              </h5>
              <TodoForm />
            </div>

            <TodoList
              type="Critical"
              title="Chamados Urgentes"
              icon="warning"
              items={this.props.todos.filter(item => item.classificacao == 1)}
            />

            <TodoList
              type="Important"
              title="Chamados Importantes"
              icon="exclamation"
              items={this.props.todos.filter(item => item.classificacao == 0)}
            />

            <TodoList
              type="Completed"
              title="Chamados Finalizados"
              icon="check"
              items={this.props.todos.filter(item => item.classificacao == 9)}
            />
          </div>
        </div>
      </JarvisWidget>
    );
  }
}

export default connect(
  store => store.todo,
  dispatch => bindActionCreators(TodoActions, dispatch)
)(TodoWidget);
