import {

    FETCH_VEICULOS_PENDING,
    FETCH_VEICULOS_FULFILLED,
    FETCH_VEICULOS_REJECTED,

    FETCH_VEICULO_PENDING,
    FETCH_VEICULO_FULFILLED,
    FETCH_VEICULO_REJECTED,

    CREATE_VEICULO_PENDING,
    CREATE_VEICULO_FULFILLED,
    CREATE_VEICULO_REJECTED,

    UPDATE_VEICULO_PENDING,
    UPDATE_VEICULO_FULFILLED,
    UPDATE_VEICULO_REJECTED,

    DELETE_VEICULO_PENDING,
    DELETE_VEICULO_FULFILLED,
    DELETE_VEICULO_REJECTED,

    FETCH_STATUS_VEICULOS_PENDING,
    FETCH_STATUS_VEICULOS_FULFILLED,
    FETCH_STATUS_VEICULOS_REJECTED,

    FETCH_ONLINE_VEICULOS_PENDING,
    FETCH_ONLINE_VEICULOS_FULFILLED,
    FETCH_ONLINE_VEICULOS_REJECTED,

    FETCH_OFFLINE_VEICULOS_PENDING,
    FETCH_OFFLINE_VEICULOS_FULFILLED,
    FETCH_OFFLINE_VEICULOS_REJECTED,
    
    CHANGE_VEICULO,
    
    ADD_VEICULO

} from './veiculoActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    statusVeiculos: {
        totalAtivos: 0,
        totalInativos: 0,
    },
    veiculosOnLine: {
        totalOnline: 0,
        total3Horas: 0,
        total6Horas: 0,
        total12Horas: 0,
    },
    listOfVeiculos: [],
    listOfOfflineVeiculos: [],
    veiculo: {
        veiId: 0,
        cliId: 0,
        tpvId: 0,
        cliId1: 0,
        cliId2: 0,
        cliId3: 0,
        veiDsc: '',
        veiPlc: '',
        veiPlcn: '',
        eqpSrn: 0,
        veiCod: '',
        veiCox: 0,
        veiMrc: '',
        veiMdl: '',
        veiCor: '',
        veiChs: '',
        veiAno: '',
        veiQst: '',
        veiSen: '',
        veiSenc: '',
        veiMcp: '',
        icoId: 0,
        veiFld: 0,
        veiDhd: null,
        veiDhc: null,
        veiVst: null,
        ptvId: 1,
        veiTd: 0,
        veiSat: 0,
        veiPmv1: 0,
        veiPmv2: 0,
        veiPmv3: 0,
        veiBlt: 0,
        veiPblt: 0,
        tsvId: 0,
        veiSts: 0
    },
    error: null,
}

export function veiculoReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_VEICULO: 
            let value = ""
            
            if (action.payload.name.includes('email')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                veiculo: { ...state.veiculo, 
                    [action.payload.name]: value
                }
            })
        case ADD_VEICULO: 
            return Object.assign({}, state, {
                veiculo: { ...initialState.veiculo }
            })
        case FETCH_VEICULOS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfVeiculos: [],
            })
        case FETCH_VEICULOS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfVeiculos: action.payload
            })
        case FETCH_VEICULOS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_VEICULO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_VEICULO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                veiculo: action.payload
            })
        case CREATE_VEICULO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_VEICULO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_VEICULO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                veiculo: action.payload
            })
        case UPDATE_VEICULO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_VEICULO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_VEICULO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                veiculo: action.payload
            })
        case FETCH_VEICULO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_VEICULO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_VEICULO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_VEICULO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_STATUS_VEICULOS_PENDING:
            return Object.assign({}, state, {
                loading: false,
                statusVeiculos: initialState.statusVeiculos
            })

        case FETCH_STATUS_VEICULOS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                statusVeiculos: action.payload
            })

        case FETCH_STATUS_VEICULOS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_ONLINE_VEICULOS_PENDING:
            return Object.assign({}, state, {
                loading: false,
                veiculosOnLine: initialState.veiculosOnLine
            })

        case FETCH_ONLINE_VEICULOS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                veiculosOnLine: action.payload
            })

        case FETCH_ONLINE_VEICULOS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_OFFLINE_VEICULOS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfOfflineVeiculos: [],
            })

        case FETCH_OFFLINE_VEICULOS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfOfflineVeiculos: action.payload
            })

        case FETCH_OFFLINE_VEICULOS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfOfflineVeiculos: [],
            })

        default:
            return state
    }
}