import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store/configureStore";
import decode from "jwt-decode";

import { authRoutes } from "./routes";
import { Layout } from "./common/layout";
import { config } from "./config/config";

const checkAuth = () => {
  const token = localStorage.getItem(config.tokenKey);
  // const refreshToken = localStorage.getItem("refreshToken");
  if (!token) { // || !refreshToken
    return false;
  }

  try {
    // { exp: 12903819203 }
    // const { exp } = decode(refreshToken);
    const { exp } = decode(token);
    console.log(exp, Math.ceil(new Date().getTime() / 1000));
    if (exp >= Math.ceil(new Date().getTime() / 1000)) {
      return true;
    }

    return false;

  } catch (e) {
    return false;
  }
};

const AuthRoute = ({ component: Component, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      checkAuth()
        ? <Component {...props} />
        : <Redirect to={{ pathname: "/login" }} />}
  />;

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            {authRoutes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => <route.component {...props} />}
                />
              ) : null;
            })}
            <AuthRoute path="/" name="Home" component={Layout} />
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
