export function empty(e) {
    if ((e) && (e instanceof String)) e = e.replace(/\s/g,"");
    switch (e) {
      case "":
      case 0:
      case "0":
      case null:
      case false:
      case undefined:
      case typeof(e) == "undefined":
        return true;
      default:
        return false;
    }
  }

export function titleCase(str) {
  return str;
  /*
    if (!str || !(typeof str === 'string')) return str;
    return str.toUpperCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' '); */
}

export function toFloat(value) {
  return parseFloat(`${value}`.replace(",","."));
}