import {

    CREATE_LOGIN_PENDING,
    CREATE_LOGIN_FULFILLED,
    CREATE_LOGIN_REJECTED,
    
    CHANGE_LOGIN

} from './loginActions'


const initialState = {
    loading: false,
    login: '',
    password: '',
    remember: false,
    token: '',
    error: null,
}

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_LOGIN:
            
        return Object.assign({}, state, { [action.payload.name]: action.payload.value })

        case CREATE_LOGIN_PENDING:
            return Object.assign({}, state, {
                loading: true,
                token: ''
            })

        case CREATE_LOGIN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                token: action.payload.token
            })

        case CREATE_LOGIN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                token: '',
                error: action.payload
            })

        default:
            return state
    }
}