import axios from "axios";
import { config } from '../config/config';

const api = axios.create({
  // baseURL: "http://localhost:3000/graphql",
  baseURL: 'http://179.127.13.142:8080/graphql',
  // baseURL: 'https://api.commandersat.com.br:443/graphql',
});

api.interceptors.request.use(async config => {
  const token = await localStorage.getItem("@MonitorWebToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
/*
api.interceptors.response.use(response => {
  return response;
}, async err => {
  return new Promise(async (resolve, reject) => {
      const originalReq = err.config;
      if ( (err.response.status === 500 || err.response.status === 401) && err.config)
      {
          /*originalReq._retry = true;
          const token = await localStorage.getItem(config.tokenKey);
          let res = await api({
            method: 'post',
            data: {
              query: `
                mutation refreshToken {
                  refreshToken (token: "${token}") {
                    token
                  }
                }
                `
            }
          }).then(async (result) => {
            const { errors, data } = result.data
            if (errors) {

            } else {
              const { refreshToken } = data
              await localStorage.setItem(config.tokenKey, refreshToken.token);
              return api(originalReq);
            }
          }).catch((errors) => {

          });
          resolve(res);
        return Promise.reject(err);
      }
  });
});*/

export default api;
