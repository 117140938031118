import {

    FETCH_CLIENTES_PENDING,
    FETCH_CLIENTES_FULFILLED,
    FETCH_CLIENTES_REJECTED,

    FETCH_CLIENTE_PENDING,
    FETCH_CLIENTE_FULFILLED,
    FETCH_CLIENTE_REJECTED,

    CREATE_CLIENTE_PENDING,
    CREATE_CLIENTE_FULFILLED,
    CREATE_CLIENTE_REJECTED,

    UPDATE_CLIENTE_PENDING,
    UPDATE_CLIENTE_FULFILLED,
    UPDATE_CLIENTE_REJECTED,

    DELETE_CLIENTE_PENDING,
    DELETE_CLIENTE_FULFILLED,
    DELETE_CLIENTE_REJECTED,

    FETCH_STATUS_CLIENTES_PENDING,
    FETCH_STATUS_CLIENTES_FULFILLED,
    FETCH_STATUS_CLIENTES_REJECTED,
    
    CHANGE_CLIENTE,
    
    ADD_CLIENTE

} from './clienteActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    statusClientes: {
        totalAtivos: 0,
        totalInativos: 0,
    },
    listOfClientes: [],
    cliente: {
        cliId: 0,
        cliMae: 0,
        cliCod: `CLI-${Math.random()}`,
        cliCox: 0,
        cliDsc: '',
        cliRua: '',
        cliNum: '',
        cliCom: '',
        cliBai: '',
        cliCid: '',
        cliUf: '',
        cliCep: '',
        cliPais: '',
        cliCon1: '',
        cliCon2: '',
        cliTel: '',
        cliFax: '',
        cliEml1: '',
        cliEml2: '',
        cliSit: '',
        cliCpf: '',
        cliCel1: '',
        cliCel2: '',
        cliIp1: 0,
        cliIp2: 0,
        cliPrt1: 0,
        cliPrt2: 0,
        cliFcp: 0,
        cliDhs: '',
        cliSts: 0,
    },
    error: null,
}

export function clienteReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CLIENTE: 
            let value = ""
            
            if (action.payload.name.includes('email')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                cliente: { ...state.cliente, 
                    [action.payload.name]: value
                }
            })
        case ADD_CLIENTE: 
            return Object.assign({}, state, {
                cliente: { ...initialState.cliente }
            })
        case FETCH_CLIENTES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfClientes: [],
            })
        case FETCH_CLIENTES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfClientes: action.payload
            })
        case FETCH_CLIENTES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CLIENTE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_CLIENTE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cliente: action.payload
            })
        case CREATE_CLIENTE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CLIENTE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CLIENTE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cliente: action.payload
            })
        case UPDATE_CLIENTE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CLIENTE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CLIENTE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cliente: action.payload
            })
        case FETCH_CLIENTE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_CLIENTE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CLIENTE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CLIENTE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_STATUS_CLIENTES_PENDING:
            return Object.assign({}, state, {
                loading: false,
                statusClientes: initialState.statusClientes,
            })
        
        case FETCH_STATUS_CLIENTES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                statusClientes: action.payload
            })

        case FETCH_STATUS_CLIENTES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}