import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Shortcut, Navigation } from "../../navigation";
import Ribbon from "./Ribbon";
import LayoutSwitcher from "./LayoutSwitcher";

import Header from "./Header";
import Footer from "./Footer";

import { config } from '../../../config/config'
import { routes } from "../../../routes";

const isAuthenticated = () => localStorage.getItem(config.tokenKey) !== null;
class Layout extends Component {
  
  componentDidMount() {
    if (isAuthenticated()) {
      this.props.history.push("/custom-dashboard")
    } else {
      this.props.history.replace('/login', null);
    }
  }

  handleLogout = async () => {
    await localStorage.removeItem(config.tokenKey);
    this.props.history.replace('/login', null);
  }

  render() {
    return (
      <div>
        <Header handleLogout={this.handleLogout} />
        <Navigation />
        <div id="main" role="main">
          {/* <LayoutSwitcher /> 
          <Ribbon />*/}

          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx} 
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => <route.component {...props} />}
                />
              ) : null;
            })}
            <Redirect from="/" to="/misc/404" />
            {/* <Redirect from="/" to="/dashboard/analytics" /> */}
          </Switch>
        </div>

        {/* <Footer />
        <Shortcut /> */}
      </div>
    );
  }
}

export default withRouter(Layout);
