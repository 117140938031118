import api from '../../../../api'
import { toFloat } from "../../../../common/utils";

export const FETCH_CLIENTES_PENDING = 'FETCH_CLIENTES_PENDING'
export const FETCH_CLIENTES_FULFILLED = 'FETCH_CLIENTES_FULFILLED'
export const FETCH_CLIENTES_REJECTED = 'FETCH_CLIENTES_REJECTED'

export const FETCH_STATUS_CLIENTES_PENDING = 'FETCH_STATUS_CLIENTES_PENDING'
export const FETCH_STATUS_CLIENTES_FULFILLED = 'FETCH_STATUS_CLIENTES_FULFILLED'
export const FETCH_STATUS_CLIENTES_REJECTED = 'FETCH_STATUS_CLIENTES_REJECTED'

export const FETCH_CLIENTE_PENDING = 'FETCH_CLIENTE_PENDING'
export const FETCH_CLIENTE_FULFILLED = 'FETCH_CLIENTE_FULFILLED'
export const FETCH_CLIENTE_REJECTED = 'FETCH_CLIENTE_REJECTED'

export const CREATE_CLIENTE_PENDING = 'CREATE_CLIENTE_PENDING'
export const CREATE_CLIENTE_FULFILLED = 'CREATE_CLIENTE_FULFILLED'
export const CREATE_CLIENTE_REJECTED = 'CREATE_CLIENTE_REJECTED'

export const UPDATE_CLIENTE_PENDING = 'UPDATE_CLIENTE_PENDING'
export const UPDATE_CLIENTE_FULFILLED = 'UPDATE_CLIENTE_FULFILLED'
export const UPDATE_CLIENTE_REJECTED = 'UPDATE_CLIENTE_REJECTED'

export const DELETE_CLIENTE_PENDING = 'DELETE_CLIENTE_PENDING'
export const DELETE_CLIENTE_FULFILLED = 'DELETE_CLIENTE_FULFILLED'
export const DELETE_CLIENTE_REJECTED = 'DELETE_CLIENTE_REJECTED'

export const CHANGE_CLIENTE = 'CHANGE_CLIENTE'

export const ADD_CLIENTE = 'ADD_CLIENTE'

export function changeCliente(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CLIENTE, payload: { name, value } })
  }
}

export function addCliente() {
  return dispatch => {
    dispatch({ type: ADD_CLIENTE });
  };
}

export function findStatusClientes() {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_STATUS_CLIENTES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query FindStatusClientes {
                  statusClientes {
                    totalAtivos
                    totalInativos
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_STATUS_CLIENTES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { statusClientes } = data
        dispatch({ type: FETCH_STATUS_CLIENTES_FULFILLED, payload: statusClientes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_STATUS_CLIENTES_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllClientes(first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CLIENTES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllClientes {
                  clientes (first: ${first} offset: ${offset} order: ["cliDsc","ASC"]) {
                    cliId
                    cliCod
                    cliCox
                    cliDsc
                    cliente {
                      cliDsc
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CLIENTES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { clientes } = data
        dispatch({ type: FETCH_CLIENTES_FULFILLED, payload: clientes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CLIENTES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findClienteByName(search = '', first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CLIENTES_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findClienteByDsc {
                  clienteByDsc (cliDsc: "%${search}%" first: ${first} offset: ${offset}) {
                    cliId
                    cliCod
                    cliCox
                    cliDsc
                    cliMae
                    cliente {
                      cliDsc
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CLIENTES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { clienteByDsc } = data
        dispatch({ type: FETCH_CLIENTES_FULFILLED, payload: clienteByDsc });
        resolve(clienteByDsc);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CLIENTES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCliente(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CLIENTE_PENDING });

    api({
      method: 'post', 
      data: {
        query: `
                query findCliente {
                  cliente (cliId: ${codigo}) {
                    cliId
                    cliCod
                    cliCox
                    cliDsc
                    cliRua
                    cliNum
                    cliCom
                    cliBai
                    cliCid
                    cliUf
                    cliCep
                    cliPais
                    cliCon1
                    cliCon2
                    cliTel
                    cliFax
                    cliEml1
                    cliEml2
                    cliSit
                    cliCpf
                    cliCel1
                    cliCel2
                    cliIp1
                    cliIp2
                    cliPrt1
                    cliPrt2
                    cliFcp
                    cliDhs
                    cliMae
                    cliSts
                    cliente {
                      cliDsc
                    }
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CLIENTE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cliente } = data
        dispatch({ type: FETCH_CLIENTE_FULFILLED, payload: cliente });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CLIENTE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCliente(cliente) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CLIENTE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createCliente {
            createCliente (input: {
              cliId: ${cliente.cliId}
              cliMae: ${cliente.cliMae}
              cliCod: "${cliente.cliCod}"
              cliCox: ${cliente.cliCox}
              cliDsc: "${cliente.cliDsc}"
              cliRua: "${cliente.cliRua}"
              cliNum: "${cliente.cliNum}"
              cliCom: "${cliente.cliCom}"
              cliBai: "${cliente.cliBai}"
              cliCid: "${cliente.cliCid}"
              cliUf: "${cliente.cliUf}"
              cliCep: "${cliente.cliCep}"
              cliPais: "${cliente.cliPais}"
              cliCon1: "${cliente.cliCon1}"
              cliCon2: "${cliente.cliCon2}"
              cliTel: "${cliente.cliTel}"
              cliFax: "${cliente.cliFax}"
              cliEml1: "${cliente.cliEml1}"
              cliEml2: "${cliente.cliEml2}"
              cliSit: "${cliente.cliSit}"
              cliCpf: "${cliente.cliCpf}"
              cliCel1: "${cliente.cliCel1}"
              cliCel2: "${cliente.cliCel2}"
              cliIp1: ${cliente.cliIp1}
              cliIp2: ${cliente.cliIp2}
              cliPrt1: ${cliente.cliPrt1}
              cliPrt2: ${cliente.cliPrt2}
              cliFcp: ${cliente.cliFcp}
              cliSts: ${cliente.cliSts}
            } ) {
              cliId
              cliMae
              cliCod
              cliCox
              cliDsc
              cliRua
              cliNum
              cliCom
              cliBai
              cliCid
              cliUf
              cliCep
              cliPais
              cliCon1
              cliCon2
              cliTel
              cliFax
              cliEml1
              cliEml2
              cliSit
              cliCpf
              cliCel1
              cliCel2
              cliIp1
              cliIp2
              cliPrt1
              cliPrt2
              cliFcp
              cliDhs
              cliSts
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CLIENTE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCliente } = data
        dispatch({ type: CREATE_CLIENTE_FULFILLED, payload: createCliente });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CLIENTE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCliente(cliente) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_CLIENTE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateCliente {
            updateCliente (input: {
              cliId: ${cliente.cliId}
              cliMae: ${cliente.cliMae}
              cliCod: "${cliente.cliCod}"
              cliCox: ${cliente.cliCox}
              cliDsc: "${cliente.cliDsc}"
              cliRua: "${cliente.cliRua}"
              cliNum: "${cliente.cliNum}"
              cliCom: "${cliente.cliCom}"
              cliBai: "${cliente.cliBai}"
              cliCid: "${cliente.cliCid}"
              cliUf: "${cliente.cliUf}"
              cliCep: "${cliente.cliCep}"
              cliPais: "${cliente.cliPais}"
              cliCon1: "${cliente.cliCon1}"
              cliCon2: "${cliente.cliCon2}"
              cliTel: "${cliente.cliTel}"
              cliFax: "${cliente.cliFax}"
              cliEml1: "${cliente.cliEml1}"
              cliEml2: "${cliente.cliEml2}"
              cliSit: "${cliente.cliSit}"
              cliCpf: "${cliente.cliCpf}"
              cliCel1: "${cliente.cliCel1}"
              cliCel2: "${cliente.cliCel2}"
              cliIp1: ${cliente.cliIp1}
              cliIp2: ${cliente.cliIp2}
              cliPrt1: ${cliente.cliPrt1}
              cliPrt2: ${cliente.cliPrt2}
              cliFcp: ${cliente.cliFcp}
              cliSts: ${cliente.cliSts}
            } ) {
              cliId
              cliMae
              cliCod
              cliCox
              cliDsc
              cliRua
              cliNum
              cliCom
              cliBai
              cliCid
              cliUf
              cliCep
              cliPais
              cliCon1
              cliCon2
              cliTel
              cliFax
              cliEml1
              cliEml2
              cliSit
              cliCpf
              cliCel1
              cliCel2
              cliIp1
              cliIp2
              cliPrt1
              cliPrt2
              cliFcp
              cliDhs
              cliSts
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_CLIENTE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateCliente } = data
        dispatch({ type: UPDATE_CLIENTE_FULFILLED, payload: updateCliente });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_CLIENTE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeCliente(cliId) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CLIENTE_PENDING });

    api({
      method: 'post',   
      data: {
        query: `
          mutation RemoveCliente {
            deleteCliente (cliId: ${cliId})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CLIENTE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCliente } = data
        dispatch({ type: DELETE_CLIENTE_FULFILLED, payload: deleteCliente });
        dispatch({ type: FETCH_CLIENTES_FULFILLED, payload: [] });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CLIENTE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
