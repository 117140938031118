import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

import { config } from "../config/config";
import { handleBodyClasses, dumpLayoutToStorage, layoutReducer, layoutInit } from "../common/layout";

import { userReducer, requestUserInfo } from "../common/user";
import { navigationReducer } from "../common/navigation";
import { chatReducer, chatInit } from "../common/chat";
import { eventsReducer } from "../common/calendar";
import { todoReducer } from "../common/todo";
import { i18nReducer, i18nInit } from "../common/i18n";

import { clienteReducer } from "../views/forms/cliente/store";
import { veiculoReducer } from "../views/forms/veiculo/store";
import { usuarioReducer } from "../views/forms/usuario/store";
import { equipamentoReducer } from "../views/forms/equipamento/store";
import { versaoReducer } from "../views/forms/versao/store";

import { trackingHistoryReducer } from "../views/historical/trackingHistory/store"
import { vehiclesCustomerReducer } from "../views/historical/vehiclesCustomer/store"
import { travelHistoryReducer } from "../views/historical/travelHistory/store"
import { distanceHistoryReducer } from "../views/historical/distanceHistory/store"
import { stoppingHistoryReducer } from "../views/historical/stoppingHistory/store"

import { employeeReducer } from "../views/forms/employee/store";
import { regionReducer } from "../views/forms/region/store";
import { countryReducer } from "../views/forms/country/store";

import { loginReducer } from "../views/auth/components/Login/store";
import { mapReducer } from "../views/maps/components/store";

import {
  voiceReducer,
  VoiceMiddleware,
  voiceControlOn
} from "../common/voice-control";

export const rootReducer = combineReducers({
  layout: layoutReducer,
  navigation: navigationReducer,
  user: userReducer,
  chat: chatReducer,
  events: eventsReducer,
  voice: voiceReducer,
  todo: todoReducer,
  i18n: i18nReducer,

  cliente: clienteReducer,
  veiculo: veiculoReducer,
  usuario: usuarioReducer,
  equipamento: equipamentoReducer,
  versao: versaoReducer,
  vehiclesCustomer: vehiclesCustomerReducer,
  trackingHistory: trackingHistoryReducer,
  travelHistory: travelHistoryReducer,
  distanceHistory: distanceHistoryReducer,
  stoppingHistory: stoppingHistoryReducer,

  employee: employeeReducer,
  region: regionReducer,
  country: countryReducer,
  map: mapReducer,
  login: loginReducer,

});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      handleBodyClasses,
      dumpLayoutToStorage,
      VoiceMiddleware
    )
  )
);

store.dispatch(layoutInit());
store.dispatch(chatInit());
store.dispatch(requestUserInfo());
store.dispatch(i18nInit());

if (config.voice_command_auto) {
  store.dispatch(voiceControlOn());
}

export default store;
