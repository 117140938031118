import api from '../../../../api'

export const FETCH_TRACKING_HISTORY_PENDING = 'FETCH_TRACKING_HISTORY_PENDING'
export const FETCH_TRACKING_HISTORY_FULFILLED = 'FETCH_TRACKING_HISTORY_FULFILLED'
export const FETCH_TRACKING_HISTORY_REJECTED = 'FETCH_TRACKING_HISTORY_REJECTED'

export const DELETE_TRACKING_HISTORY_PENDING = 'DELETE_TRACKING_HISTORY_PENDING'
export const DELETE_TRACKING_HISTORY_FULFILLED = 'DELETE_TRACKING_HISTORY_FULFILLED'
export const DELETE_TRACKING_HISTORY_REJECTED = 'DELETE_TRACKING_HISTORY_REJECTED'

export const CHANGE_TRACKING_HISTORY = 'CHANGE_TRACKING_HISTORY'

export function changeTrackingHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TRACKING_HISTORY, payload: { name, value } })
  }
}

export function findAllTrackingHistorys(filter, first = 1000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TRACKING_HISTORY_PENDING });
    /**
      velocidadeTipo: ${filter.velocidadeTipo}
      velocidadeValor: ${filter.velocidadeValor}
     */
    api({
      method: 'post',
      data: {
        query: `
                query findAllTrackingHistorys {
                  relatorioPosicoes(
                    cliId: 784 
                    veiId: ${filter.veiculo} 
                    parDhi: "${filter.dataHoraInicial}"
                    parDhf: "${filter.dataHoraFinal}"
                    velTip: ${filter.velocidadeTipo}
                    velVlr: ${filter.velocidadeValor}
                    first: ${first} offset: ${offset}) {
                    veiId
                    veiCod
                    veiPlc
                    veiDsc
                    cliDsc
                    ptpDsc
                    ddlDh
                    ddlVel
                    ddlIgn
                    ddlLat
                    ddlLng
                    ddlDir
                    ddlIgn
                    ddlOdm
                    ddlTen
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TRACKING_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { relatorioPosicoes } = data
        dispatch({ type: FETCH_TRACKING_HISTORY_FULFILLED, payload: relatorioPosicoes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TRACKING_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}