import api from '../../../../api'

export const FETCH_DISTANCE_HISTORY_PENDING = 'FETCH_DISTANCE_HISTORY_PENDING'
export const FETCH_DISTANCE_HISTORY_FULFILLED = 'FETCH_DISTANCE_HISTORY_FULFILLED'
export const FETCH_DISTANCE_HISTORY_REJECTED = 'FETCH_DISTANCE_HISTORY_REJECTED'

export const DELETE_DISTANCE_HISTORY_PENDING = 'DELETE_DISTANCE_HISTORY_PENDING'
export const DELETE_DISTANCE_HISTORY_FULFILLED = 'DELETE_DISTANCE_HISTORY_FULFILLED'
export const DELETE_DISTANCE_HISTORY_REJECTED = 'DELETE_DISTANCE_HISTORY_REJECTED'

export const CHANGE_DISTANCE_HISTORY = 'CHANGE_DISTANCE_HISTORY'

export function changeDistanceHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_DISTANCE_HISTORY, payload: { name, value } })
  }
}

export function findAllDistanceHistorys(filter, first = 1000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_DISTANCE_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllDistanceHistorys {
                  distanciaPercorridaPorPeriodo(
                    veiId: ${filter.veiculo} 
                    parDhi: "${filter.dataHoraInicial}"
                    parDhf: "${filter.dataHoraFinal}") {
                      rptPer
                      rptDst
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_DISTANCE_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { distanciaPercorridaPorPeriodo } = data
        dispatch({ type: FETCH_DISTANCE_HISTORY_FULFILLED, payload: distanciaPercorridaPorPeriodo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_DISTANCE_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}