import {

    FETCH_EQUIPAMENTOS_PENDING,
    FETCH_EQUIPAMENTOS_FULFILLED,
    FETCH_EQUIPAMENTOS_REJECTED,

    FETCH_EQUIPAMENTO_PENDING,
    FETCH_EQUIPAMENTO_FULFILLED,
    FETCH_EQUIPAMENTO_REJECTED,

    CREATE_EQUIPAMENTO_PENDING,
    CREATE_EQUIPAMENTO_FULFILLED,
    CREATE_EQUIPAMENTO_REJECTED,

    UPDATE_EQUIPAMENTO_PENDING,
    UPDATE_EQUIPAMENTO_FULFILLED,
    UPDATE_EQUIPAMENTO_REJECTED,

    DELETE_EQUIPAMENTO_PENDING,
    DELETE_EQUIPAMENTO_FULFILLED,
    DELETE_EQUIPAMENTO_REJECTED,

    FETCH_STATUS_EQUIPAMENTOS_PENDING,
    FETCH_STATUS_EQUIPAMENTOS_FULFILLED,
    FETCH_STATUS_EQUIPAMENTOS_REJECTED,
    
    CHANGE_EQUIPAMENTO,
    
    ADD_EQUIPAMENTO

} from './equipamentoActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    statusEquipamentos: {
        totalAtivos: 0,
        totalInativos: 0,
    },
    listOfEquipamentos: [],
    equipamento: {
        codigo: 0,
        verId: 0,
        eqpCox: 0,
        eqpSrt: '',
        eqpDdd: '',
        eqpTel: '',
        eqpPin: '',
        eqpPuk: '', 
        eqpOpc: '',
        eqpUvp: '',
        eqpDhs: '',
        eqpDtmf: '',
        eqpCk1: 0,
        eqpCk2: 0,
        eqpCk3: 0,
        eqpCkdh: '',
        cliId: 0,
        eqpMtc: 0,
        eqpSts: 0,
        cliente: {
            cliId: 0,
            cliDsc: '',
        },
        versao: {
            verId: 0,
            verDsc: ''
        }
    },
    error: null,
}

export function equipamentoReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EQUIPAMENTO: 
            let value = ""
            
            if (action.payload.name.includes('email')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                equipamento: { ...state.equipamento, 
                    [action.payload.name]: value
                }
            })
        case ADD_EQUIPAMENTO: 
            return Object.assign({}, state, {
                equipamento: { ...initialState.equipamento }
            })
        case FETCH_EQUIPAMENTOS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEquipamentos: [],
            })
        case FETCH_EQUIPAMENTOS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfEquipamentos: action.payload
            })
        case FETCH_EQUIPAMENTOS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_EQUIPAMENTO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_EQUIPAMENTO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                equipamento: action.payload
            })
        case CREATE_EQUIPAMENTO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_EQUIPAMENTO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_EQUIPAMENTO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                equipamento: action.payload
            })
        case UPDATE_EQUIPAMENTO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_EQUIPAMENTO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_EQUIPAMENTO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                equipamento: action.payload
            })
        case FETCH_EQUIPAMENTO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_EQUIPAMENTO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EQUIPAMENTO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EQUIPAMENTO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_STATUS_EQUIPAMENTOS_PENDING:
            return Object.assign({}, state, {
                loading: false,
                statusEquipamentos: initialState.statusEquipamentos
            })

        case FETCH_STATUS_EQUIPAMENTOS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                statusEquipamentos: action.payload
            })

        case FETCH_STATUS_EQUIPAMENTOS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}