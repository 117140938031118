import api from '../../../../api'

export const FETCH_EQUIPAMENTOS_PENDING = 'FETCH_EQUIPAMENTOS_PENDING'
export const FETCH_EQUIPAMENTOS_FULFILLED = 'FETCH_EQUIPAMENTOS_FULFILLED'
export const FETCH_EQUIPAMENTOS_REJECTED = 'FETCH_EQUIPAMENTOS_REJECTED'

export const FETCH_STATUS_EQUIPAMENTOS_PENDING = 'FETCH_STATUS_EQUIPAMENTOS_PENDING'
export const FETCH_STATUS_EQUIPAMENTOS_FULFILLED = 'FETCH_STATUS_EQUIPAMENTOS_FULFILLED'
export const FETCH_STATUS_EQUIPAMENTOS_REJECTED = 'FETCH_STATUS_EQUIPAMENTOS_REJECTED'

export const FETCH_EQUIPAMENTO_PENDING = 'FETCH_EQUIPAMENTO_PENDING'
export const FETCH_EQUIPAMENTO_FULFILLED = 'FETCH_EQUIPAMENTO_FULFILLED'
export const FETCH_EQUIPAMENTO_REJECTED = 'FETCH_EQUIPAMENTO_REJECTED'

export const CREATE_EQUIPAMENTO_PENDING = 'CREATE_EQUIPAMENTO_PENDING'
export const CREATE_EQUIPAMENTO_FULFILLED = 'CREATE_EQUIPAMENTO_FULFILLED'
export const CREATE_EQUIPAMENTO_REJECTED = 'CREATE_EQUIPAMENTO_REJECTED'

export const UPDATE_EQUIPAMENTO_PENDING = 'UPDATE_EQUIPAMENTO_PENDING'
export const UPDATE_EQUIPAMENTO_FULFILLED = 'UPDATE_EQUIPAMENTO_FULFILLED'
export const UPDATE_EQUIPAMENTO_REJECTED = 'UPDATE_EQUIPAMENTO_REJECTED'

export const DELETE_EQUIPAMENTO_PENDING = 'DELETE_EQUIPAMENTO_PENDING'
export const DELETE_EQUIPAMENTO_FULFILLED = 'DELETE_EQUIPAMENTO_FULFILLED'
export const DELETE_EQUIPAMENTO_REJECTED = 'DELETE_EQUIPAMENTO_REJECTED'

export const CHANGE_EQUIPAMENTO = 'CHANGE_EQUIPAMENTO'

export const ADD_EQUIPAMENTO = 'ADD_EQUIPAMENTO'

export function changeEquipamento(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_EQUIPAMENTO, payload: { name, value } })
  }
}

export function addEquipamento() {
  return dispatch => {
    dispatch({ type: ADD_EQUIPAMENTO });
  };
}

export function findStatusEquipamentos() {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_STATUS_EQUIPAMENTOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query FindStatusEquipamentos {
                  statusEquipamentos {
                    totalAtivos
                    totalInativos
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_STATUS_EQUIPAMENTOS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { statusEquipamentos } = data
        dispatch({ type: FETCH_STATUS_EQUIPAMENTOS_FULFILLED, payload: statusEquipamentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_STATUS_EQUIPAMENTOS_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllEquipamentos(first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EQUIPAMENTOS_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findAllEquipamentos {
                  equipamentos (first: ${first} offset: ${offset}) {
                    codigo: eqpSrn
                    verId
                    eqpCox
                    eqpSrt
                    eqpDdd
                    eqpTel
                    eqpOpc
                    cliId
                    eqpMtc
                    eqpSts
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EQUIPAMENTOS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { equipamentos } = data
        dispatch({ type: FETCH_EQUIPAMENTOS_FULFILLED, payload: equipamentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EQUIPAMENTOS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findEquipamentoBySrn(query = '') {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EQUIPAMENTOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllEquipamentosByName {
                  equipamentoBySrn(eqpSrn: "%${query}%") {
                    codigo: eqpSrn
                    verId
                    eqpCox
                    eqpSrt
                    eqpDdd
                    eqpTel
                    eqpOpc
                    cliId
                    eqpMtc
                    eqpSts
                    cliente {
                      cliDsc
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EQUIPAMENTOS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { equipamentoBySrn } = data
        dispatch({ type: FETCH_EQUIPAMENTOS_FULFILLED, payload: equipamentoBySrn });
        resolve(equipamentoBySrn);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EQUIPAMENTOS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findEquipamento(eqpSrn) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EQUIPAMENTO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findEquipamento {
                  equipamento (eqpSrn: ${eqpSrn}) {
                    codigo: eqpSrn
                    verId
                    eqpCox
                    eqpSrt
                    eqpDdd
                    eqpTel
                    eqpPin
                    eqpPuk
                    eqpOpc
                    eqpUvp
                    eqpDhs
                    eqpDtmf
                    eqpCk1
                    eqpCk2
                    eqpCk3
                    eqpCkdh
                    cliId
                    eqpMtc
                    eqpSts
                    versao {
                      verDsc
                    }
                    cliente {
                      cliDsc
                    }
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EQUIPAMENTO_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { equipamento } = data
        dispatch({ type: FETCH_EQUIPAMENTO_FULFILLED, payload: equipamento });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EQUIPAMENTO_REJECTED, error });
      resolve(error);
    });

  });

}

export function createEquipamento(equipamento) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_EQUIPAMENTO_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createEquipamento {
            createEquipamento (input: {
              eqpSrn: ${equipamento.codigo}
              verId: ${equipamento.verId}
              eqpCox: ${equipamento.eqpCox}
              eqpSrt: "${equipamento.eqpSrt}"
              eqpDdd: "${equipamento.eqpDdd}"
              eqpTel: "${equipamento.eqpTel}"
              eqpPin: "${equipamento.eqpPin}"
              eqpPuk: "${equipamento.eqpPuk}"
              eqpOpc: "${equipamento.eqpOpc}"
              eqpDtmf: "${equipamento.eqpDtmf}"
              cliId: ${equipamento.cliId}
              eqpMtc: ${equipamento.eqpMtc}
              eqpSts: ${equipamento.eqpSts}
            } ) {
              codigo: eqpSrn
              verId
              eqpCox
              eqpSrt
              eqpDdd
              eqpTel
              eqpPin
              eqpPuk
              eqpOpc
              eqpUvp
              eqpDhs
              eqpDtmf
              eqpCk1
              eqpCk2
              eqpCk3
              eqpCkdh
              cliId
              eqpMtc
              eqpSts
              versao {
                verDsc
              }
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_EQUIPAMENTO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createEquipamento } = data
        dispatch({ type: CREATE_EQUIPAMENTO_FULFILLED, payload: createEquipamento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_EQUIPAMENTO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateEquipamento(equipamento) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_EQUIPAMENTO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateEquipamento {
           updateEquipamento (input: {
            eqpSrn: ${equipamento.codigo}
            verId: ${equipamento.verId}
            eqpCox: ${equipamento.eqpCox}
            eqpSrt: "${equipamento.eqpSrt}"
            eqpDdd: "${equipamento.eqpDdd}"
            eqpTel: "${equipamento.eqpTel}"
            eqpPin: "${equipamento.eqpPin}"
            eqpPuk: "${equipamento.eqpPuk}"
            eqpOpc: "${equipamento.eqpOpc}"
            eqpDtmf: "${equipamento.eqpDtmf}"
            cliId: ${equipamento.cliId}
            eqpMtc: ${equipamento.eqpMtc}
            eqpSts: ${equipamento.eqpSts}
          } ) {
              codigo: eqpSrn
              verId
              eqpCox
              eqpSrt
              eqpDdd
              eqpTel
              eqpPin
              eqpPuk
              eqpOpc
              eqpUvp
              eqpDhs
              eqpDtmf
              eqpCk1
              eqpCk2
              eqpCk3
              eqpCkdh
              cliId
              eqpMtc
              eqpSts
              versao {
                verDsc
              }
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_EQUIPAMENTO_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateEquipamento } = data
        dispatch({ type: UPDATE_EQUIPAMENTO_FULFILLED, payload: updateEquipamento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_EQUIPAMENTO_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeEquipamento(eqpSrn) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_EQUIPAMENTO_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation RemoveEquipamento {
            deleteEquipamento (eqpSrn: ${eqpSrn})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_EQUIPAMENTO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteEquipamento } = data
        dispatch({ type: DELETE_EQUIPAMENTO_FULFILLED, payload: deleteEquipamento });
        dispatch(findAllEquipamentos());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_EQUIPAMENTO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
