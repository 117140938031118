import {

    FETCH_DISTANCE_HISTORY_PENDING,
    FETCH_DISTANCE_HISTORY_FULFILLED,
    FETCH_DISTANCE_HISTORY_REJECTED,

    DELETE_DISTANCE_HISTORY_PENDING,
    DELETE_DISTANCE_HISTORY_FULFILLED,
    DELETE_DISTANCE_HISTORY_REJECTED,

    CHANGE_DISTANCE_HISTORY

} from './distanceHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfDistanceHistory: [],
    distanceHistory: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss"),
        dataHoraInicial: moment().utc().format("YYYY-MM-DD") + ' 00:00:00',
        dataHoraFinal: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    error: null,
}

export function distanceHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_DISTANCE_HISTORY:
            return Object.assign({}, state, {
                distanceHistory: {
                    ...state.distanceHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_DISTANCE_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfDistanceHistory: [],
            })
        case FETCH_DISTANCE_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfDistanceHistory: action.payload
            })
        case FETCH_DISTANCE_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfDistanceHistory: [],
                error: action.payload
            })

        case DELETE_DISTANCE_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_DISTANCE_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_DISTANCE_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}