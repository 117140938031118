import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const ListCliente = Loadable({
  loader: () => import("./cliente/ListCliente"),
  loading: Loading
});

const FormCliente = Loadable({
  loader: () => import("./cliente/FormCliente"),
  loading: Loading
});

const ListVeiculo = Loadable({
  loader: () => import("./veiculo/ListVeiculo"),
  loading: Loading
});

const FormVeiculo = Loadable({
  loader: () => import("./veiculo/FormVeiculo"),
  loading: Loading
});

const ListEquipamento = Loadable({
  loader: () => import("./equipamento/ListEquipamento"),
  loading: Loading
});

const FormEquipamento = Loadable({
  loader: () => import("./equipamento/FormEquipamento"),
  loading: Loading
});

const ListUsuario = Loadable({
  loader: () => import("./usuario/ListUsuario"),
  loading: Loading
});

const FormUsuario = Loadable({
  loader: () => import("./usuario/FormUsuario"),
  loading: Loading
});

//-----------------------------------------------------------------------------

const FormElements = Loadable({
  loader: () => import("./components/FormElements"),
  loading: Loading
});

const FormLayouts = Loadable({
  loader: () => import("./components/layouts/FormLayouts"),
  loading: Loading
});

const FormValidation = Loadable({
  loader: () => import("./components/FormValidation"),
  loading: Loading
});

const BootstrapFormElements = Loadable({
  loader: () => import("./components/BootstrapFormElements"),
  loading: Loading
});

const BootstrapValidation = Loadable({
  loader: () => import("./components/bootstrap-validation/BootstrapValidation"),
  loading: Loading
});

const FormPlugins = Loadable({
  loader: () => import("./components/form-plugins/FormPlugins"),
  loading: Loading
});

const Wizards = Loadable({
  loader: () => import("./components/wizards/Wizards"),
  loading: Loading
});

const DropzoneDemo = Loadable({
  loader: () => import("./components/DropzoneDemo"),
  loading: Loading
});

const ImageCropping = Loadable({
  loader: () => import("./components/ImageCropping"),
  loading: Loading
});

export const routes = [
  {
    path: "/clientes",
    exact: true,
    component: ListCliente,
    name: "List Cliente"
  },
  {
    path: "/forms/cliente",
    exact: true,
    component: FormCliente,
    name: "Form Cliente"
  },

  {
    path: "/veiculos",
    exact: true,
    component: ListVeiculo,
    name: "List Veiculo"
  },
  {
    path: "/forms/veiculo",
    exact: true,
    component: FormVeiculo,
    name: "Form Veiculo"
  },

  {
    path: "/equipamentos",
    exact: true,
    component: ListEquipamento,
    name: "List Equipamento"
  },
  {
    path: "/forms/equipamento",
    exact: true,
    component: FormEquipamento,
    name: "Form Equipamento"
  },

  {
    path: "/usuarios",
    exact: true,
    component: ListUsuario,
    name: "List Usuario"
  },
  {
    path: "/forms/usuario",
    exact: true,
    component: FormUsuario,
    name: "Form Usuario"
  },

  //-----------------------------------------------------------------------------
  {
    path: "/forms/elements",
    exact: true,
    component: FormElements,
    name: "Form Elements"
  },
  {
    path: "/forms/layouts",
    exact: true,
    component: FormLayouts,
    name: "Form Layouts"
  },
  {
    path: "/forms/form-validation",
    exact: true,
    component: FormValidation,
    name: "Form Validation"
  },
  {
    path: "/forms/bootstrap-form-elements",
    exact: true,
    component: BootstrapFormElements,
    name: "Bootstrap Form Elements"
  },
  {
    path: "/forms/bootstrap-validation",
    exact: true,
    component: BootstrapValidation,
    name: "Bootstrap Valliidation"
  },
  {
    path: "/forms/plugins",
    exact: true,
    component: FormPlugins,
    name: "Form Plugins"
  },
  {
    path: "/forms/wizards",
    exact: true,
    component: Wizards,
    name: "Wizards"
  },
  {
    path: "/forms/dropzone",
    exact: true,
    component: DropzoneDemo,
    name: "Dropzone"
  },
  {
    path: "/forms/image-editor",
    exact: true,
    component: ImageCropping,
    name: "Image Cropping"
  }
];
