import api from '../../../../api'

export const FETCH_COUNTRY_PENDING = 'FETCH_COUNTRY_PENDING'
export const FETCH_COUNTRY_FULFILLED = 'FETCH_COUNTRY_FULFILLED'
export const FETCH_COUNTRY_REJECTED = 'FETCH_COUNTRY_REJECTED'

export function findAllCountries(region, search) {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({ type: FETCH_COUNTRY_PENDING });

        api({
            method: 'post',   
            data: {
                query: `
          query findAllCountries {
            municipios(region: ${region} search: "%${search}%" first: 1000 offset: 0) {
                codigo
                nome
                uf
            }
          }
          `
            }
        }).then((result) => {
            const { errors, data } = result.data
            if (errors) {
                dispatch({ 
                  type: FETCH_COUNTRY_REJECTED, 
                  payload: errors });
                resolve(errors);
            } else {
                const { municipios } = data
                dispatch({ 
                  type: FETCH_COUNTRY_FULFILLED, 
                  payload: municipios });
                resolve(data);
            }
        }).catch((error) => {
            dispatch({ 
              type: FETCH_COUNTRY_REJECTED, 
              error 
            });
            resolve(error);
        });

    });

}
