import React, { Component } from "react";
import styled from 'styled-components';
import XlsExport from 'xlsexport';

import {
  BigBreadcrumbs,
  WidgetGrid,
  JarvisWidget
} from "../../../common";

import jsPDF from 'jspdf'
import 'jspdf-autotable';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom";

import $ from 'jquery';
import { findAllPositionsFromCustomer } from "../../maps/components/store";

const Loading = () => {
  return (<i className="fa fa-spinner fa-spin fadeInOut" style={{ fontSize: "24px", opacity: 0.8 }} />);
}

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const moment = require('moment');
class VehiclesCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customers: [],
    };
  }

  componentDidMount() {
    this.props.findAllPositionsFromCustomer(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.positions !== this.props.positions) {
      const _customers = {};
      for (const pos of this.props.positions) {
        if (!_customers[pos.cliDsc]) _customers[pos.cliDsc] = { vehicles: [] };
        _customers[pos.cliDsc].vehicles.push(pos);
      }
      this.setState({ customers: _customers });
    }
  }

  handleExportXLS = () => {
    // const { listOfVehiclesCustomer } = this.props;

    // let data = listOfVehiclesCustomer.map((row) => ({
    //   DataHoraServidor: row.ddlDh,
    //   DataHoraGps: row.ddlDh,
    //   Placa: row.veiPlc,
    //   Descricao: row.veiDsc,
    //   Ignicao: row.ddlIgn === 0 ? 'Desligada' : 'Ligada',
    //   Velocidade: row.ddlVel.toFixed(0),
    //   Alimentacao: row.ddlTen,
    //   Hodometro: 0,
    //   Latitude: row.ddlLat,
    //   Longitude: row.ddlLng,
    //   PontoProximo: row.ptpDsc,
    //   ExibirLocalizacao: '=HIPERLINK("https://www.google.com/maps/preview?q=Placa:+'+row.veiPlc+'+-+Descrição:+'+row.veiDsc+'+Registro:+'+row.ddlDh+'%29%40+'+row.ddlLat+','+row.ddlLng+'&t=h";"Exibir Localização")',
    // }))

    // var xls = new XlsExport(data, "HistóricoRastreamento");
    // xls.exportToXLS(`HistoricoRastreamento_${moment().format('YYYYMMDDHHmmss')}.xls`);

  }

  // handlePrintReport = () => {
  //   const { vehiclesCustomer, listOfVehiclesCustomer } = this.props;

  //   var doc = new jsPDF({
  //     orientation: 'p',
  //     unit: 'mm',
  //     format: 'a4',
  //     compress: true,
  //     fontSize: 11,
  //     lineHeight: 1,
  //     autoSize: false,
  //     printHeaders: true
  //   });

  //   doc.setProperties({
  //       title: 'MonitorWeb',
  //       subject: '',
  //       author: 'Marcelo de Alencastro silva',
  //       keywords: '',
  //       creator: 'author'
  //   });

  //   doc.page = 1;
  //   var width = doc.internal.pageSize.getWidth();
  //   var height = doc.internal.pageSize.getHeight();

  //   var header = function () {
  //       var imgData = ""; // Convert the image to base64 and place it here //
  //       // doc.setFontStyle('normal');
  //       // move_from_left, move_from_height, width, height
  //       //doc.addImage(imgData, 'JPEG', 5, 10, width - 10, 65)
  //       doc.setFont('Courier', 'bold');
  //       doc.setFontSize(9);
  //       // move_from_left, move_from_height
  //       doc.text(10, 10, 'MonitorWeb - Movimentação no Período\r\n');
  //       doc.text(10, 15, `Período: ${vehiclesCustomer.dataInicial} ${vehiclesCustomer.horaInicial} à ${vehiclesCustomer.dataFinal} ${vehiclesCustomer.horaFinal}`);
  //       //
  //       doc.setFontSize(6);
  //       doc.text(width - 40, 10, `Emissão: ${moment().format("DD/MM/YYYY HH:mm:ss")}`);

  //       doc.setFontSize(12);
  //       doc.line(0, 18, width, 18);
  //   };

  //   var footer = function () {
  //     var imgData = "";// Convert the image to base64 and place it here //
  //       //print number bottom right
  //       doc.setFontSize(12);
  //       doc.line(0, height - 10, width, height - 10);
  //       doc.setFontSize(7);
  //       doc.setFont('Courier', 'italic');
  //       doc.text(width - 20, height - 5, 'Página - ' + doc.page);
  //       doc.page++;
  //       //_________________________________
  //       //doc.addImage(imgData, 'JPEG', 5, height - 25, width - 10, 30)
  //   };

  //   // doc.autoTable(col, data, options);
  //   let body = listOfVehiclesCustomer.map((row) => ([
  //     row.ddlDh,
  //     row.veiPlc,
  //     row.veiDsc,
  //     row.ddlIgn === 0 ? 'Desligada' : 'Ligada',
  //     row.ddlVel.toFixed(0) + ' km/h',
  //     row.ddlTen + ' volts',
  //     row.ptpDsc,
  //     row.ddlOdm.toFixed(1) + ' km',
  //   ]));

  //   doc.autoTable({
  //     startY: 20,
  //     head: [['Data e Hora GPS', 'Placa', 'Descrição', 'Ignição', 'Velocidade', 'Alimentação', 'Ponto Mais Próximo', 'Odômetro']],
  //     body: body,
  //     beforePageContent: header,
  //     afterPageContent: footer,
  //     theme: 'grid',
  //     headStyles: {
  //       font: 'Courier',
  //       fontSize: 8,
  //       fillColor: 'gray',
  //       textColor: 'white'
  //     },
  //     style: {
  //       font: 'Courier',
  //       fontSize: 8,
  //       cellWidth: 'auto',
  //       rowHeight: 8,
  //       valign: 'middle'
  //     },
  //     columnStyles: {
  //         0: {
  //           // columnWidth: '70%',
  //           fontSize: 8,
  //           //fontStyle: 'bold',
  //           halign: 'center',
  //         },
  //         1: {
  //             // columnWidth: '30%',
  //             fontSize: 8,
  //             //fontStyle: 'bold',
  //             //halign: 'center',
  //         },
  //         2: {
  //           // columnWidth: '30%',
  //           fontSize: 8,
  //           //fontStyle: 'bold',
  //           //halign: 'center',
  //         },
  //         3: {
  //             // columnWidth: '30%',
  //             fontSize: 8,
  //             //fontStyle: 'bold',
  //             //halign: 'center',
  //         },
  //         4: {
  //             // columnWidth: '30%',
  //             fontSize: 8,
  //             //fontStyle: 'bold',
  //             halign: 'right',
  //         },
  //         5: {
  //             // columnWidth: '30%',
  //             fontSize: 8,
  //             //fontStyle: 'bold',
  //             //halign: 'center',
  //         },
  //         6: {
  //             // columnWidth: '30%',
  //             fontSize: 8,
  //             //fontStyle: 'bold',
  //             //halign: 'center',
  //         }
  //     },
  //     margin: {
  //       top: 20,
  //       bottom: 10,
  //       horizontal: 5
  //     },
  //     didParseCell(data) {
  //       /*if (data.row.index === 0) {
  //         data.cell.styles.textColor = [255, 255, 255];
  //         data.cell.styles.fillColor = '#FF5783';
  //       }*/
  //     }
  //   });

  //   let finalY = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
  //   // doc.setFontSize(9);
  //   // doc.setFont('Courier', 'italic');
  //   // doc.text(`Total no Período: ${distance.toFixed(3)} Km`, width - (width * 0.30), finalY + 10); //you use the variable and add the number of pixels you want it to move.
  //   // doc.save('Generate.pdf')
  //   doc.output('dataurlnewwindow')

  // }

  render() {
    const { customers } = this.state;
    return (
      <div id="content">
        <div className="row">
          <BigBreadcrumbs
            items={["Veículos por Pasta / Cliente"]}
            icon="fa fa-fw fa-bar-chart-o"
            className="col-xs-12 col-sm-7 col-md-7 col-lg-4"
          />
        </div>
        <WidgetGrid>
          <div className="row">
            <article className="col-xs-12 col-sm-12">
              <JarvisWidget id="wid-id-table-results"
                colorbutton={false}
                deletebutton={false}
                fullscreenbutton={false}
                editbutton={false}
                custombutton={true}>
                <header>
                  <span className="widget-icon">
                    <i className="fa fa-bar-chart-o" />
                  </span>
                  <h2>Listagem de Veículos por Cliente</h2>
                </header>

                <div>
                  <div className="widget-body no-padding" style={{
                    position: 'relative',
                    height: '450px',
                    overflow: 'auto',
                    display: 'block',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap'
                  }}>
                    <table className="table table-bordered tableFixHead">
                      <thead>
                        <tr>
                          <th>Pasta / Cliente</th>
                          <th style={{ textAlign: 'center' }}>Veiculos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(customers).sort().map((row, index) => {
                          return (
                            <>
                              <tr key={`history-customer-${index}`}>
                                <td style={{ fontWeight: 'bold' }}>{row}</td>
                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{customers[row].vehicles.length}</td>
                              </tr>
                              <table className="table table-bordered" key={`history-customer-tb-${index}`}>
                                <thead>
                                  <tr>
                                    <th style={{ textAlign: 'center', width: 100 }}>Data / Hora GPS</th>
                                    <th style={{ textAlign: 'center', width: 100 }}>Placa</th>
                                    <th style={{ textAlign: 'center' }}>Descrição</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {customers[row].vehicles.sort((a, b) => {
                                    const nameA = a.veiPlc.toUpperCase();
                                    const nameB = b.veiPlc.toUpperCase();
                                    if (nameA < nameB) {
                                      return -1;
                                    }
                                    if (nameA > nameB) {
                                      return 1;
                                    }
                                    return 0;
                                  }).map((rw, index) => {
                                    return (
                                      <tr key={`history-det-${index}`}>
                                        <td style={{ textAlign: 'center', width: 100 }}>{rw.udlDh}</td>
                                        <td style={{ textAlign: 'center', width: 100 }}>{rw.veiPlc}</td>
                                        <td>{rw.veiDsc}</td>
                                      </tr>)
                                  })}
                                </tbody>
                              </table>
                            </>)
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </JarvisWidget>
            </article>

          </div>

        </WidgetGrid>

      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  loading: state.map.loading,
  positions: state.map.positions,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  findAllPositionsFromCustomer
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesCustomer);
