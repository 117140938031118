import moment from "moment";

import {
  TOGGLE_NEW_TODO,
  TOGGLE_CLOSE_TODO,
  TOGGLE_TODO,
  UPDATE_TODO,
  DELETE_TODO,
  FETCH_DATA_FULFILLED,

  FETCH_CREATE_DATA_PENDING,
  FETCH_CREATE_DATA_FULFILLED,
  FETCH_CREATE_DATA_REJECTED,

  FETCH_CLOSE_DATA_PENDING,
  FETCH_CLOSE_DATA_FULFILLED,
  FETCH_CLOSE_DATA_REJECTED,
} from "./TodoActions";
/*
{
  id: "542d47f5e3355bf80ebdf83f",
  type: "Critical",
  title: "Correções Sistema",
  completedAt: null,
  createdAt: moment().subtract(2, "days")
}*/
export function todoReducer(
  state = {
    newTodo: false,
    todos: [],
    todo: null,
  },
  action
) {
  switch (action.type) {
    case TOGGLE_CLOSE_TODO:
      return { ...state, newTodo: !state.newTodo, todo: action.payload };
    case TOGGLE_NEW_TODO:
      return { ...state, newTodo: !state.newTodo, todo: {
        codigo: 0,
        titulo: "",
        descricao: "",
        classificacao: "0",
        aquivo: "",
      } };
    case TOGGLE_TODO:
    case UPDATE_TODO:
      return {
        ...state,
        todos: state.todos.map(item => {
          if (item.id !== action.payload.id) {
            return item;
          } else {
            return {
              ...item,
              ...action.payload
            };
          }
        })
      };
    case DELETE_TODO:
      return {
        ...state,
        todos: state.todos.filter(_ => _.id !== action.payload.id)
      };
    case FETCH_CLOSE_DATA_FULFILLED:
      return {
        ...state,
        todos: state.todos.map(item => {
          if (item.codigo !== action.payload.codigo) {
            return item;
          } else {
            return {
              ...item,
              ...action.payload
            };
          }
        })
      };
    case FETCH_CREATE_DATA_FULFILLED:
      return {
        ...state,
        todos: [action.payload, ...state.todos]
      };
    case FETCH_DATA_FULFILLED: 
      return {
        ...state,
        todos: action.payload
      };
    default:
      return state;
  }
}
