import api from '../../../../api'

export const FETCH_VEICULOS_PENDING = 'FETCH_VEICULOS_PENDING'
export const FETCH_VEICULOS_FULFILLED = 'FETCH_VEICULOS_FULFILLED'
export const FETCH_VEICULOS_REJECTED = 'FETCH_VEICULOS_REJECTED'

export const FETCH_ONLINE_VEICULOS_PENDING = 'FETCH_ONLINE_VEICULOS_PENDING'
export const FETCH_ONLINE_VEICULOS_FULFILLED = 'FETCH_ONLINE_VEICULOS_FULFILLED'
export const FETCH_ONLINE_VEICULOS_REJECTED = 'FETCH_ONLINE_VEICULOS_REJECTED'

export const FETCH_OFFLINE_VEICULOS_PENDING = 'FETCH_OFFLINE_VEICULOS_PENDING'
export const FETCH_OFFLINE_VEICULOS_FULFILLED = 'FETCH_OFFLINE_VEICULOS_FULFILLED'
export const FETCH_OFFLINE_VEICULOS_REJECTED = 'FETCH_OFFLINE_VEICULOS_REJECTED'

export const FETCH_STATUS_VEICULOS_PENDING = 'FETCH_STATUS_VEICULOS_PENDING'
export const FETCH_STATUS_VEICULOS_FULFILLED = 'FETCH_STATUS_VEICULOS_FULFILLED'
export const FETCH_STATUS_VEICULOS_REJECTED = 'FETCH_STATUS_VEICULOS_REJECTED'

export const FETCH_VEICULO_PENDING = 'FETCH_VEICULO_PENDING'
export const FETCH_VEICULO_FULFILLED = 'FETCH_VEICULO_FULFILLED'
export const FETCH_VEICULO_REJECTED = 'FETCH_VEICULO_REJECTED'

export const CREATE_VEICULO_PENDING = 'CREATE_VEICULO_PENDING'
export const CREATE_VEICULO_FULFILLED = 'CREATE_VEICULO_FULFILLED'
export const CREATE_VEICULO_REJECTED = 'CREATE_VEICULO_REJECTED'

export const UPDATE_VEICULO_PENDING = 'UPDATE_VEICULO_PENDING'
export const UPDATE_VEICULO_FULFILLED = 'UPDATE_VEICULO_FULFILLED'
export const UPDATE_VEICULO_REJECTED = 'UPDATE_VEICULO_REJECTED'

export const DELETE_VEICULO_PENDING = 'DELETE_VEICULO_PENDING'
export const DELETE_VEICULO_FULFILLED = 'DELETE_VEICULO_FULFILLED'
export const DELETE_VEICULO_REJECTED = 'DELETE_VEICULO_REJECTED'

export const CHANGE_VEICULO = 'CHANGE_VEICULO'

export const ADD_VEICULO = 'ADD_VEICULO'

export function changeVeiculo(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_VEICULO, payload: { name, value } })
  }
}

export function addVeiculo() {
  return dispatch => {
    dispatch({ type: ADD_VEICULO });
  };
}

export function findDelayedVehicles(hours = 3) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_OFFLINE_VEICULOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query FindDelayedVehicles {
                  veiculosEmAtraso(horas: ${hours}) {
                    veiPlc
                    veiDth
                    udlLat
                    udlLng
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_OFFLINE_VEICULOS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculosEmAtraso } = data
        dispatch({ type: FETCH_OFFLINE_VEICULOS_FULFILLED, payload: veiculosEmAtraso });
        resolve(veiculosEmAtraso);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_OFFLINE_VEICULOS_REJECTED, error });
      reject(error);
    });

  });

}

export function findVeiculosOnLine() {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_ONLINE_VEICULOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query FindVeiculosOnLine {
                  veiculosOnLine {
                    totalOnline
                    total3Horas
                    total6Horas
                    total12Horas
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_ONLINE_VEICULOS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculosOnLine } = data
        dispatch({ type: FETCH_ONLINE_VEICULOS_FULFILLED, payload: veiculosOnLine });
        resolve(veiculosOnLine);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ONLINE_VEICULOS_REJECTED, error });
      reject(error);
    });

  });

}

export function findStatusVeiculos() {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_STATUS_VEICULOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query FindStatusVeiculos {
                  statusVeiculos {
                    totalAtivos
                    totalInativos
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_STATUS_VEICULOS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { statusVeiculos } = data
        dispatch({ type: FETCH_STATUS_VEICULOS_FULFILLED, payload: statusVeiculos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_STATUS_VEICULOS_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllVeiculos(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEICULOS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllVeiculos {
                  veiculos (first: ${first} offset: ${offset} order: ["veiDsc","ASC"]) {
                    veiId
                    veiCod
                    veiDsc
                    veiPlc
                    veiPlcn
                    eqpSrn
                    veiCox
                    cliente {
                      cliDsc
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEICULOS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { veiculos } = data
        dispatch({ type: FETCH_VEICULOS_FULFILLED, payload: veiculos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEICULOS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findVeiculoByDsc(search = '', veiFld = '0,1', first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEICULOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllVeiculosByDsc {
                  veiculoByDsc (veiDsc: "%${search}%" veiFld: "${veiFld}" first: ${first} offset: ${offset}) {
                    veiId
                    veiCod
                    veiDsc
                    veiPlc
                    veiPlcn
                    eqpSrn
                    veiCox
                    cliente {
                      cliDsc
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEICULOS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { veiculoByDsc } = data
        dispatch({ type: FETCH_VEICULOS_FULFILLED, payload: veiculoByDsc });
        resolve(veiculoByDsc);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEICULOS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findVeiculo(veiId) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEICULO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findVeiculo {
                  veiculo (veiId: ${veiId}) {
                    veiId
                    cliId
                    tpvId
                    cliId1
                    cliId2
                    cliId3
                    veiCod
                    veiDsc
                    veiPlc
                    veiPlcn
                    eqpSrn
                    veiCox
                    veiMrc
                    veiMdl
                    veiCor
                    veiChs
                    veiAno
                    veiQst
                    veiSen
                    veiSenc
                    veiMcp
                    icoId
                    veiFld
                    veiDhd
                    veiDhs
                    veiDhc
                    ptvId
                    veiTd
                    veiSat
                    veiPmv1
                    veiPmv2
                    veiPmv3
                    veiBlt
                    veiPblt
                    veiVst
                    tsvId
                    veiSts
                    cliente {
                      cliDsc
                    }
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEICULO_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { veiculo } = data
        dispatch({ type: FETCH_VEICULO_FULFILLED, payload: veiculo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEICULO_REJECTED, error });
      resolve(error);
    });

  });

}

export function createVeiculo(veiculo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_VEICULO_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createVeiculo {
            createVeiculo (input: {
              veiId: ${veiculo.veiId}
              cliId: ${veiculo.cliId}
              tpvId: ${veiculo.tpvId}
              cliId1: ${veiculo.cliId1}
              cliId2: ${veiculo.cliId2}
              cliId3: ${veiculo.cliId3}
              veiCod: "${veiculo.veiCod}"
              veiDsc: "${veiculo.veiDsc}"
              veiPlc: "${veiculo.veiPlc}"
              veiPlcn: "${veiculo.veiPlcn}"
              eqpSrn: ${veiculo.eqpSrn}
              veiCox: ${veiculo.veiCox}
              veiMrc: "${veiculo.veiMrc}"
              veiMdl: "${veiculo.veiMdl}"
              veiCor: "${veiculo.veiCor}"
              veiChs: "${veiculo.veiChs}"
              veiAno: "${veiculo.veiAno}"
              veiQst: "${veiculo.veiQst}"
              veiSen: "${veiculo.veiSen}"
              veiSenc: "${veiculo.veiSenc}"
              veiMcp: "${veiculo.veiMcp}"
              icoId: ${veiculo.icoId}
              veiFld: ${veiculo.veiFld}
              ptvId: ${veiculo.ptvId}
              veiTd: ${veiculo.veiTd}
              veiSat: ${veiculo.veiSat}
              veiPmv1: ${veiculo.veiPmv1}
              veiPmv2: ${veiculo.veiPmv2}
              veiPmv3: ${veiculo.veiPmv3}
              veiBlt: ${veiculo.veiBlt}
              veiPblt: ${veiculo.veiPblt}
              tsvId: ${veiculo.tsvId}
              veiSts: ${veiculo.veiSts}
            } ) {
              veiId
              cliId
              tpvId
              cliId1
              cliId2
              cliId3
              veiCod
              veiDsc
              veiPlc
              veiPlcn
              eqpSrn
              veiCox
              veiMrc
              veiMdl
              veiCor
              veiChs
              veiAno
              veiQst
              veiSen
              veiSenc
              veiMcp
              icoId
              veiFld
              veiDhd
              veiDhs
              veiDhc
              ptvId
              veiTd
              veiSat
              veiPmv1
              veiPmv2
              veiPmv3
              veiBlt
              veiPblt
              veiVst
              tsvId
              veiSts
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_VEICULO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createVeiculo } = data
        dispatch({ type: CREATE_VEICULO_FULFILLED, payload: createVeiculo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_VEICULO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateVeiculo(veiculo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_VEICULO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateVeiculo {
            updateVeiculo (input: {
              veiId: ${veiculo.veiId}
              cliId: ${veiculo.cliId}
              tpvId: ${veiculo.tpvId}
              cliId1: ${veiculo.cliId1}
              cliId2: ${veiculo.cliId2}
              cliId3: ${veiculo.cliId3}
              veiCod: "${veiculo.veiCod}"
              veiDsc: "${veiculo.veiDsc}"
              veiPlc: "${veiculo.veiPlc}"
              veiPlcn: "${veiculo.veiPlcn}"
              eqpSrn: ${veiculo.eqpSrn}
              veiCox: ${veiculo.veiCox}
              veiMrc: "${veiculo.veiMrc}"
              veiMdl: "${veiculo.veiMdl}"
              veiCor: "${veiculo.veiCor}"
              veiChs: "${veiculo.veiChs}"
              veiAno: "${veiculo.veiAno}"
              veiQst: "${veiculo.veiQst}"
              veiSen: "${veiculo.veiSen}"
              veiSenc: "${veiculo.veiSenc}"
              veiMcp: "${veiculo.veiMcp}"
              icoId: ${veiculo.icoId}
              veiFld: ${veiculo.veiFld}
              veiDhs: "${veiculo.veiDhs}"
              ptvId: ${veiculo.ptvId}
              veiTd: ${veiculo.veiTd}
              veiSat: ${veiculo.veiSat}
              veiPmv1: ${veiculo.veiPmv1}
              veiPmv2: ${veiculo.veiPmv2}
              veiPmv3: ${veiculo.veiPmv3}
              veiBlt: ${veiculo.veiBlt}
              veiPblt: ${veiculo.veiPblt}
              tsvId: ${veiculo.tsvId}
              veiSts: ${veiculo.veiSts}
            } ) {
              veiId
              cliId
              tpvId
              cliId1
              cliId2
              cliId3
              veiCod
              veiDsc
              veiPlc
              veiPlcn
              eqpSrn
              veiCox
              veiMrc
              veiMdl
              veiCor
              veiChs
              veiAno
              veiQst
              veiSen
              veiSenc
              veiMcp
              icoId
              veiFld
              veiDhd
              veiDhs
              veiDhc
              ptvId
              veiTd
              veiSat
              veiPmv1
              veiPmv2
              veiPmv3
              veiBlt
              veiPblt
              veiVst
              tsvId
              veiSts
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_VEICULO_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateVeiculo } = data
        dispatch({ type: UPDATE_VEICULO_FULFILLED, payload: updateVeiculo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_VEICULO_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeVeiculo(veiId) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_VEICULO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveVeiculo {
            deleteVeiculo (veiId: ${veiId})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_VEICULO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteVeiculo } = data
        dispatch({ type: DELETE_VEICULO_FULFILLED, payload: deleteVeiculo });
        dispatch({ type: FETCH_VEICULOS_FULFILLED, payload: [] });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_VEICULO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
