import {

    FETCH_USUARIOS_PENDING,
    FETCH_USUARIOS_FULFILLED,
    FETCH_USUARIOS_REJECTED,

    FETCH_USUARIO_PENDING,
    FETCH_USUARIO_FULFILLED,
    FETCH_USUARIO_REJECTED,

    CREATE_USUARIO_PENDING,
    CREATE_USUARIO_FULFILLED,
    CREATE_USUARIO_REJECTED,

    UPDATE_USUARIO_PENDING,
    UPDATE_USUARIO_FULFILLED,
    UPDATE_USUARIO_REJECTED,

    DELETE_USUARIO_PENDING,
    DELETE_USUARIO_FULFILLED,
    DELETE_USUARIO_REJECTED,
    
    CHANGE_USUARIO,
    
    ADD_USUARIO

} from './usuarioActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfUsuarios: [],
    usuario: {
        usrId: 0,
        tusId: 0,
        cliId: 0,
        usrCfg: '',
        usrDhe: '',
        usrIp: 0,
        usrLgn: '',
        usrPwd: '',
        usrPwdc: '',
        usrNom: '',
        usrTel: '',
        usrEml: '',
        usrDhs: '',
        usrPml: 0,
        tipoUsuario: {
            tusDsc: '',
            tusDac: '',
        },
        cliente: {
            cliDsc: '',
        },
    },
    error: null,
}

export function usuarioReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_USUARIO: 
            let value = ""
            
            if (action.payload.name.includes('email')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                usuario: { ...state.usuario, 
                    [action.payload.name]: value
                }
            })
        case ADD_USUARIO: 
            return Object.assign({}, state, {
                usuario: { ...initialState.usuario }
            })
        case FETCH_USUARIOS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfUsuarios: [],
            })
        case FETCH_USUARIOS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfUsuarios: action.payload
            })
        case FETCH_USUARIOS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_USUARIO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_USUARIO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                usuario: action.payload
            })
        case CREATE_USUARIO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_USUARIO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_USUARIO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                usuario: action.payload
            })
        case UPDATE_USUARIO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_USUARIO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_USUARIO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                usuario: action.payload
            })
        case FETCH_USUARIO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_USUARIO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_USUARIO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_USUARIO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}