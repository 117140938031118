import api from '../../../../../api'
import { config } from '../../../../../config/config'

export const CREATE_LOGIN_PENDING = 'CREATE_LOGIN_PENDING'
export const CREATE_LOGIN_FULFILLED = 'CREATE_LOGIN_FULFILLED'
export const CREATE_LOGIN_REJECTED = 'CREATE_LOGIN_REJECTED'

export const CHANGE_LOGIN = 'CHANGE_LOGIN'

export function changeLogin(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_LOGIN, payload: { name, value } })
  }
}

export function createLoginAndToken(login, password) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_LOGIN_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation createToken {
            createToken ( login: "${login}" password: "${password}" ) { 
              token 
            }
          }
          `
      }
    }).then(async (result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_LOGIN_REJECTED, payload: errors });
        reject({ status: false, error: errors[0] });
      } else {
        const { createToken } = data
        await localStorage.setItem(config.tokenKey, createToken.token);
        dispatch({ type: CREATE_LOGIN_FULFILLED, payload: createToken });
        resolve({ status: true, token: createToken.token }); 
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_LOGIN_REJECTED, errors });
      reject(errors);
    });
  });
}

