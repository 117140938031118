import api from '../../../../api'

export const FETCH_POSICAO_PENDING = 'FETCH_POSICAO_PENDING'
export const FETCH_POSICAO_FULFILLED = 'FETCH_POSICAO_FULFILLED'
export const FETCH_POSICAO_REJECTED = 'FETCH_POSICAO_REJECTED'

export function findAllPositionsFromCustomer(cliId) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_POSICAO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllPositionsFromCustomer {
                  ultimasPosicoesPorCliente(cliId: ${cliId}) {
                    veiId
                    veiCod
                    veiPlc
                    veiDsc
                    ptpDsc
                    cliDsc
                    udlDh
                    udlDhs
                    udlVel
                    udlHrm
                    udlOdm
                    udlTen
                    udlTmp
                    udlLat
                    udlLng
                    udlMod
                    udlDir
                    udlIgn
                    udlGpr
                    udlPan
                    udlEn1
                    udlEn2
                    udlEn3
                    udlMov
                    udlJam
                    udlGps
                    udlNvl
                    udlAce
                    udlMtv
                    udlSa1
                    udlSa2
                    udlSa3
                    udlSa4
                    udlSa5
                    ptvSa1
                    ptvSa2
                    ptvSa3
                    ptvSa4
                    ptvSa5
                    ptvSa6
                    ptvSa7
                    ptvSa8
                    udlPro
                    motId
                    stsGpr
                    udlOdm
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_POSICAO_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { ultimasPosicoesPorCliente } = data
        dispatch({ type: FETCH_POSICAO_FULFILLED, payload: ultimasPosicoesPorCliente });
        resolve(ultimasPosicoesPorCliente);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_POSICAO_REJECTED, payload: error });
      resolve(error);
    });

  });

}
