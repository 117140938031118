import api from '../../../../api'
import { toFloat } from "../../../../common/utils";

export const FETCH_VERSOES_PENDING = 'FETCH_VERSOES_PENDING'
export const FETCH_VERSOES_FULFILLED = 'FETCH_VERSOES_FULFILLED'
export const FETCH_VERSOES_REJECTED = 'FETCH_VERSOES_REJECTED'

export const FETCH_VERSAO_PENDING = 'FETCH_VERSAO_PENDING'
export const FETCH_VERSAO_FULFILLED = 'FETCH_VERSAO_FULFILLED'
export const FETCH_VERSAO_REJECTED = 'FETCH_VERSAO_REJECTED'

export const CREATE_VERSAO_PENDING = 'CREATE_VERSAO_PENDING'
export const CREATE_VERSAO_FULFILLED = 'CREATE_VERSAO_FULFILLED'
export const CREATE_VERSAO_REJECTED = 'CREATE_VERSAO_REJECTED'

export const UPDATE_VERSAO_PENDING = 'UPDATE_VERSAO_PENDING'
export const UPDATE_VERSAO_FULFILLED = 'UPDATE_VERSAO_FULFILLED'
export const UPDATE_VERSAO_REJECTED = 'UPDATE_VERSAO_REJECTED'

export const DELETE_VERSAO_PENDING = 'DELETE_VERSAO_PENDING'
export const DELETE_VERSAO_FULFILLED = 'DELETE_VERSAO_FULFILLED'
export const DELETE_VERSAO_REJECTED = 'DELETE_VERSAO_REJECTED'

export const CHANGE_VERSAO = 'CHANGE_VERSAO'

export const ADD_VERSAO = 'ADD_VERSAO'

export function changeVersao(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_VERSAO, payload: { name, value } })
  }
}

export function addVersao() {
  return dispatch => {
    dispatch({ type: ADD_VERSAO });
  };
}

export function findAllVersoes(first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VERSOES_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllVersoes {
                  versoes (first: ${first} offset: ${offset}) {
                    verId
                    verCod
                    verCox
                    verDsc
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VERSOES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { versoes } = data
        dispatch({ type: FETCH_VERSOES_FULFILLED, payload: versoes });
        resolve(versoes);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VERSOES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findVersao(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VERSAO_PENDING });

    api({
      method: 'post',          
      data: {
        query: `
                query findVersao {
                  versao (codigo: ${codigo}) {
                    cliCod
                    cliCox
                    cliDsc
                    cliRua
                    cliNum
                    cliCom
                    cliBai
                    cliCid
                    cliUf
                    cliCep
                    cliPais
                    cliCon1
                    cliCon2
                    cliTel
                    cliFax
                    cliEml1
                    cliEml2
                    cliSit
                    cliCpf
                    cliCel1
                    cliCel2
                    cliIp1
                    cliIp2
                    cliPrt1
                    cliPrt2
                    cliFcp
                    cliDhs
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VERSAO_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { versao } = data
        dispatch({ type: FETCH_VERSAO_FULFILLED, payload: versao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VERSAO_REJECTED, error });
      resolve(error);
    });

  });

}

export function createVersao(Versao) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_VERSAO_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createVersao {
            inclusaoVersao (input: {
              status: "${Versao.status}"
              nome: "${Versao.nome}"
              nascimento: "${Versao.nascimento}"  
              mae: "${Versao.mae}"
              cpf: "${Versao.cpf}"
              rg: "${Versao.rg}"
              endereco: "${Versao.endereco}"
              numero: "${Versao.numero}"
              complemento: "${Versao.complemento}"
              cep: "${Versao.cep}"
              bairro: "${Versao.bairro}"
              cidade: "${Versao.cidade}"
              estado: "${Versao.estado}"
              telefone: "${Versao.telefone}"
              telefoneRecado: "${Versao.telefoneRecado}"
              celular: "${Versao.celular}"
              email: "${Versao.email}"
              empresaTrabalho: "${Versao.empresaTrabalho}"
              empresaTelefone: "${Versao.empresaTelefone}"
              empresaAdmissao: "${Versao.empresaAdmissao}"
              empresaRenda: ${toFloat(Versao.empresaRenda)}
              complementoRenda: ${toFloat(Versao.complementoRenda)}
              casaPropria: "${Versao.casaPropria}"
              veiculoProprio: "${Versao.veiculoProprio}"
              empresaCompra01: "${Versao.empresaCompra01}"
              empresaTelefone01: "${Versao.empresaTelefone01}"
              empresaCompra02: "${Versao.empresaCompra02}"
              empresaTelefone02: "${Versao.empresaTelefone02}"
              bancoConta01: "${Versao.bancoConta01}"
              bancoAbertura01: "${Versao.bancoAbertura01}"
              bancoTelefone01: "${Versao.bancoTelefone01}"
              bancoConta02: "${Versao.bancoConta02}"
              bancoAbertura02: "${Versao.bancoAbertura02}"
              bancoTelefone02: "${Versao.bancoTelefone02}"
              limiteCompra: ${toFloat(Versao.limiteCompra)}
              diaFechamento: ${Versao.diaFechamento}
            } ) {
              codigo
              nome
              nascimento  
              mae
              cpf
              rg
              endereco
              numero
              complemento
              cep
              bairro
              cidade
              estado
              status
              telefone
              telefoneRecado
              celular
              email
              empresaTrabalho
              empresaTelefone
              empresaAdmissao
              empresaRenda
              complementoRenda
              casaPropria
              veiculoProprio
              empresaCompra01
              empresaTelefone01
              empresaCompra02
              empresaTelefone02
              bancoConta01
              bancoAbertura01
              bancoTelefone01
              bancoConta02
              bancoAbertura02
              bancoTelefone02
              limiteCompra
              saldoDisponivel
              diaFechamento
              ultimaCompra
              dataCadastro
              usuarioCadastro
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_VERSAO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { inclusaoVersao } = data
        dispatch({ type: CREATE_VERSAO_FULFILLED, payload: inclusaoVersao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_VERSAO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateVersao(Versao) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_VERSAO_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateVersao {
            atualizacaoVersao (codigo: ${Versao.codigo} input: {
              status: "${Versao.status}"
              nome: "${Versao.nome}"
              nascimento: "${Versao.nascimento}"  
              mae: "${Versao.mae}"
              cpf: "${Versao.cpf}"
              rg: "${Versao.rg}"
              endereco: "${Versao.endereco}"
              numero: "${Versao.numero}"
              complemento: "${Versao.complemento}"
              cep: "${Versao.cep}"
              bairro: "${Versao.bairro}"
              cidade: "${Versao.cidade}"
              estado: "${Versao.estado}"
              telefone: "${Versao.telefone}"
              telefoneRecado: "${Versao.telefoneRecado}"
              celular: "${Versao.celular}"
              email: "${Versao.email}"
              empresaTrabalho: "${Versao.empresaTrabalho}"
              empresaTelefone: "${Versao.empresaTelefone}"
              empresaAdmissao: "${Versao.empresaAdmissao}"
              empresaRenda: ${toFloat(Versao.empresaRenda)}
              complementoRenda: ${toFloat(Versao.complementoRenda)}
              casaPropria: "${Versao.casaPropria}"
              veiculoProprio: "${Versao.veiculoProprio}"
              empresaCompra01: "${Versao.empresaCompra01}"
              empresaTelefone01: "${Versao.empresaTelefone01}"
              empresaCompra02: "${Versao.empresaCompra02}"
              empresaTelefone02: "${Versao.empresaTelefone02}"
              bancoConta01: "${Versao.bancoConta01}"
              bancoAbertura01: "${Versao.bancoAbertura01}"
              bancoTelefone01: "${Versao.bancoTelefone01}"
              bancoConta02: "${Versao.bancoConta02}"
              bancoAbertura02: "${Versao.bancoAbertura02}"
              bancoTelefone02: "${Versao.bancoTelefone02}"
              limiteCompra: ${toFloat(Versao.limiteCompra)}
              diaFechamento: ${Versao.diaFechamento}
            } ) {
              codigo
              nome
              nascimento  
              mae
              cpf
              rg
              endereco
              numero
              complemento
              cep
              bairro
              cidade
              estado
              status
              telefone
              telefoneRecado
              celular
              email
              empresaTrabalho
              empresaTelefone
              empresaAdmissao
              empresaRenda
              complementoRenda
              casaPropria
              veiculoProprio
              empresaCompra01
              empresaTelefone01
              empresaCompra02
              empresaTelefone02
              bancoConta01
              bancoAbertura01
              bancoTelefone01
              bancoConta02
              bancoAbertura02
              bancoTelefone02
              limiteCompra
              saldoDisponivel
              diaFechamento
              ultimaCompra
              dataCadastro
              usuarioCadastro
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_VERSAO_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { atualizacaoVersao } = data
        dispatch({ type: UPDATE_VERSAO_FULFILLED, payload: atualizacaoVersao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_VERSAO_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeVersao(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_VERSAO_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveVersao {
            deleteVersao (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_VERSAO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteVersao } = data
        dispatch({ type: DELETE_VERSAO_FULFILLED, payload: deleteVersao });
        dispatch(findAllVersoes());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_VERSAO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
