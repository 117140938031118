import {

    FETCH_REGION_PENDING,
    FETCH_REGION_FULFILLED,
    FETCH_REGION_REJECTED,

} from './regionActions'

const initialState = {
    isFetchingRegion: false,
    listOfRegions: [],
    errorOfRegion: ''
}

export function regionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_REGION_PENDING:
            return Object.assign({}, state, {
                isFetchingRegion: true,
            })
        case FETCH_REGION_FULFILLED:
            return Object.assign({}, state, {
                isFetchingRegion: false,
                listOfRegions: action.payload
            })
        case FETCH_REGION_REJECTED:
            return Object.assign({}, state, {
                isFetchingRegion: false,
                errorOfRegion: action.payload
            })
        default:
            return state
    }
}