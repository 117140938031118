import React from "react";
import classnames from "classnames";

import { Moment } from "../../utils";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as TodoActions from "../TodoActions";

class TodoList extends React.Component {

  componentDidMount() {
    this.props.findAllTechnicalCalls(1);
  };

  toggleItem = item => {
    this.props.toggleTodo(item);
  };

  toggleCloseItem = item => {
    this.props.toggleCloseTodo(item);
  };

  deleteItem = item => {
    this.props.deleteTodo(item);
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.items.length !== this.props.items.length;
  };

  render() {
    return (
      <div>
        <h5 className="todo-group-title">
          <i className={classnames("fa", "fa-" + this.props.icon)} style={{ paddingRight: 10 }} />
          {this.props.title}(
          <small className="num-of-tasks">{this.props.items.length}</small>)
        </h5>
        <ul className="todo" ref="todoList">
          {this.props.items.map((item, idx) => {
            return (
              <li
                className={classnames({ complete: item.completedAt })}
                key={item.codigo}
                data-todo-id={item.codigo}
              >
                <span className="handle">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      disabled={true}
                      onClick={() => this.toggleItem(item)}
                      defaultChecked={item.completedAt}
                      name="checkbox-inline"
                    />
                    <i />
                  </label>
                </span>
                <p>
                  <strong>Chamado #{item.codigo}</strong>
                  <em> - </em>
                  <big>{item.titulo}</big>
                  <span className="text-muted">{item.descricao}</span>
                  <span className="date">
                    Aberto em <Moment date={item.createdAt} format="DD/MM/YYYY HH:mm:ss" />
                  </span>
                  {item.classificacao === 9 &&
                    <span className="date">
                      Fechado em <Moment date={item.updatedAt} format="DD/MM/YYYY HH:mm:ss" />
                    </span>
                  }
                  {item.classificacao !== 9 &&
                    <button onClick={() => { this.toggleCloseItem(item) }} className="btn btn-link btn-xs">
                      <i className="fa fa-edit" style={{ fontSize: 18 }} />
                    </button>}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}



export default connect(
  store => store.todo,
  dispatch => bindActionCreators(TodoActions, dispatch)
)(TodoList);
