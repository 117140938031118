import api from '../../../../api'

export const FETCH_USUARIOS_PENDING = 'FETCH_USUARIOS_PENDING'
export const FETCH_USUARIOS_FULFILLED = 'FETCH_USUARIOS_FULFILLED'
export const FETCH_USUARIOS_REJECTED = 'FETCH_USUARIOS_REJECTED'

export const FETCH_USUARIO_PENDING = 'FETCH_USUARIO_PENDING'
export const FETCH_USUARIO_FULFILLED = 'FETCH_USUARIO_FULFILLED'
export const FETCH_USUARIO_REJECTED = 'FETCH_USUARIO_REJECTED'

export const CREATE_USUARIO_PENDING = 'CREATE_USUARIO_PENDING'
export const CREATE_USUARIO_FULFILLED = 'CREATE_USUARIO_FULFILLED'
export const CREATE_USUARIO_REJECTED = 'CREATE_USUARIO_REJECTED'

export const UPDATE_USUARIO_PENDING = 'UPDATE_USUARIO_PENDING'
export const UPDATE_USUARIO_FULFILLED = 'UPDATE_USUARIO_FULFILLED'
export const UPDATE_USUARIO_REJECTED = 'UPDATE_USUARIO_REJECTED'

export const DELETE_USUARIO_PENDING = 'DELETE_USUARIO_PENDING'
export const DELETE_USUARIO_FULFILLED = 'DELETE_USUARIO_FULFILLED'
export const DELETE_USUARIO_REJECTED = 'DELETE_USUARIO_REJECTED'

export const CHANGE_USUARIO = 'CHANGE_USUARIO'

export const ADD_USUARIO = 'ADD_USUARIO'

export function changeUsuario(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_USUARIO, payload: { name, value } })
  }
}

export function addUsuario() {
  return dispatch => {
    dispatch({ type: ADD_USUARIO });
  };
}

export function findAllUsuarios(first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USUARIOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllUsuarios {
                  usuarios (first: ${first} offset: ${offset} order: ["usrNom","ASC"]) {
                    usrId
                    tusId
                    cliId
                    usrLgn
                    usrNom
                    usrTel
                    usrEml
                    cliente {
                      cliDsc
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USUARIOS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuarios } = data
        dispatch({ type: FETCH_USUARIOS_FULFILLED, payload: usuarios });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USUARIOS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findUsuarioByName(search = '', first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USUARIOS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findUsuariosByName {
                  usuariosByName (usrNom: "%${search}%" first: ${first} offset: ${offset} order: ["usrNom","ASC"]) {
                    usrId
                    tusId
                    cliId
                    usrLgn
                    usrNom
                    usrTel
                    usrEml
                    cliente {
                      cliDsc
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USUARIOS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuariosByName } = data
        dispatch({ type: FETCH_USUARIOS_FULFILLED, payload: usuariosByName });
        resolve(usuariosByName);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USUARIOS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findUsuario(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USUARIO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findUsuario {
                  usuario (usrId: ${codigo}) {
                    usrId
                    tusId
                    cliId
                    usrCfg
                    usrDhe
                    usrIp
                    usrLgn
                    usrNom
                    usrTel
                    usrEml
                    usrDhs
                    usrPml
                    tipoUsuario {
                      tusDsc
                      tusDac
                    }
                    cliente {
                      cliDsc
                    }
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USUARIO_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuario } = data
        dispatch({ type: FETCH_USUARIO_FULFILLED, payload: usuario });
        resolve(usuario);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USUARIO_REJECTED, error });
      resolve(error);
    });

  });

}

export function createUsuario(usuario) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_USUARIO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createUsuario {
            createUsuario (input: {
              usrId: ${usuario.usrId}
              tusId: ${usuario.tusId}
              cliId: ${usuario.cliId}
              usrLgn: "${usuario.usrLgn}"
              usrPwd: "${usuario.usrPwd}"
              usrNom: "${usuario.usrNom}"
              usrTel: "${usuario.usrTel}"
              usrEml: "${usuario.usrEml}"
              usrPml: ${usuario.usrPml}
            } ) {
              usrId
              tusId
              cliId
              usrCfg
              usrDhe
              usrIp
              usrLgn
              usrPwd
              usrNom
              usrTel
              usrEml
              usrDhs
              usrPml
              tipoUsuario {
                tusDsc
              }
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_USUARIO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createUsuario } = data
        dispatch({ type: CREATE_USUARIO_FULFILLED, payload: createUsuario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_USUARIO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateUsuario(usuario) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_USUARIO_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateUsuario {
            updateUsuario (input: {
              usrId: ${usuario.usrId}
              tusId: ${usuario.tusId}
              cliId: ${usuario.cliId}
              usrLgn: "${usuario.usrLgn}"
              usrPwd: "${usuario.usrPwd}"
              usrNom: "${usuario.usrNom}"
              usrTel: "${usuario.usrTel}"
              usrEml: "${usuario.usrEml}"
              usrPml: ${usuario.usrPml}
            } ) {
              usrId
              tusId
              cliId
              usrCfg
              usrDhe
              usrIp
              usrLgn
              usrPwd
              usrNom
              usrTel
              usrEml
              usrDhs
              usrPml
              tipoUsuario {
                tusDsc
              }
              cliente {
                cliDsc
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_USUARIO_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateUsuario } = data
        dispatch({ type: UPDATE_USUARIO_FULFILLED, payload: updateUsuario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_USUARIO_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeUsuario(usrId) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_USUARIO_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveUsuario {
            deleteUsuario (usrId: ${usrId})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_USUARIO_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteUsuario } = data
        dispatch({ type: DELETE_USUARIO_FULFILLED, payload: deleteUsuario });
        dispatch({ type: FETCH_USUARIOS_FULFILLED, payload: [] });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_USUARIO_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
