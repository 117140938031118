import React from 'react'
import * as jwtDecode from 'jwt-decode'
import NavMenuItem from './NavMenuItem'
import { config } from '../../../config/config'

const isMaster = () => {
  if (localStorage.getItem(config.tokenKey) !== null) {
    var decoded = jwtDecode(localStorage.getItem(config.tokenKey));
    return decoded.cliCod === 784 || decoded.usuCod === 1097 || decoded.usuCod === 1;
  }
  return false;
}

export default function SmartMenuList(props) {

  const {items, ...p} = props;

  return ( 
    <ul {...p}>
      {items.map((item) => {
        return (!isMaster() && item.title === 'Cadastros') ? null : <NavMenuItem item={item} key={item.id}/>;
      })}
    </ul>
  )
}
