import {

    FETCH_TRAVEL_HISTORY_PENDING,
    FETCH_TRAVEL_HISTORY_FULFILLED,
    FETCH_TRAVEL_HISTORY_REJECTED,

    CHANGE_TRAVEL_HISTORY

} from './travelHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTravelHistory: [],
    travelHistory: {
        veiculo: 0,
        descricao: '',
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function travelHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TRAVEL_HISTORY:
            return Object.assign({}, state, {
                travelHistory: {
                    ...state.travelHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TRAVEL_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTravelHistory: [],
            })
        case FETCH_TRAVEL_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTravelHistory: action.payload
            })
        case FETCH_TRAVEL_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTravelHistory: [],
                error: action.payload
            })

        default:
            return state
    }
}