import {

    FETCH_VERSOES_PENDING,
    FETCH_VERSOES_FULFILLED,
    FETCH_VERSOES_REJECTED,

    FETCH_VERSAO_PENDING,
    FETCH_VERSAO_FULFILLED,
    FETCH_VERSAO_REJECTED,

    CREATE_VERSAO_PENDING,
    CREATE_VERSAO_FULFILLED,
    CREATE_VERSAO_REJECTED,

    UPDATE_VERSAO_PENDING,
    UPDATE_VERSAO_FULFILLED,
    UPDATE_VERSAO_REJECTED,

    DELETE_VERSAO_PENDING,
    DELETE_VERSAO_FULFILLED,
    DELETE_VERSAO_REJECTED,
    
    CHANGE_VERSAO,
    
    ADD_VERSAO

} from './versaoActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfEquipamentos: [],
    versao: {
        eqpSrn: 0,
        verId: 0,
        eqpCox: 0,
        eqpSrt: '',
        eqpDdd: '',
        eqpTel: '',
        eqpPin: '',
        eqpPuk: '',
        eqpOpc: '',
        eqpUvp: '',
        eqpDhs: '',
        eqpDtmf: '',
        eqpCk1: 0,
        eqpCk2: 0,
        eqpCk3: 0,
        eqpCkdh: '',
        cliId: 0,
        eqpMtc: 0,
    },
    error: null,
}

export function versaoReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_VERSAO: 
            let value = ""
            
            if (action.payload.name.includes('email')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                versao: { ...state.versao, 
                    [action.payload.name]: value
                }
            })
        case ADD_VERSAO: 
            return Object.assign({}, state, {
                versao: { ...initialState.versao }
            })
        case FETCH_VERSOES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEquipamentos: [],
            })
        case FETCH_VERSOES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfEquipamentos: action.payload
            })
        case FETCH_VERSOES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_VERSAO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_VERSAO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                versao: action.payload
            })
        case CREATE_VERSAO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_VERSAO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_VERSAO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                versao: action.payload
            })
        case UPDATE_VERSAO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_VERSAO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_VERSAO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                versao: action.payload
            })
        case FETCH_VERSAO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_VERSAO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_VERSAO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_VERSAO_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}