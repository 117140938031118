import {

    FETCH_STOPPING_HISTORY_PENDING,
    FETCH_STOPPING_HISTORY_FULFILLED,
    FETCH_STOPPING_HISTORY_REJECTED,

    CHANGE_STOPPING_HISTORY

} from './stoppingHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfStoppingHistory: [],
    stoppingHistory: {
        veiculo: 0,
        descricao: '',
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function stoppingHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_STOPPING_HISTORY:
            return Object.assign({}, state, {
                stoppingHistory: {
                    ...state.stoppingHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_STOPPING_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfStoppingHistory: [],
            })
        case FETCH_STOPPING_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfStoppingHistory: action.payload
            })
        case FETCH_STOPPING_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfStoppingHistory: [],
                error: action.payload
            })

        default:
            return state
    }
}