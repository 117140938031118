import {

    FETCH_POSICAO_PENDING,
    FETCH_POSICAO_FULFILLED,
    FETCH_POSICAO_REJECTED,

} from './mapActions'

const initialState = {
    loading: false,
    positions: [],
}

export function mapReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_POSICAO_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_POSICAO_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                positions: action.payload
            })
        case FETCH_POSICAO_REJECTED:
            return Object.assign({}, state, {

            })
        default:
            return state
    }
}