import {

    FETCH_EMPLOYEES_PENDING,
    FETCH_EMPLOYEES_FULFILLED,
    FETCH_EMPLOYEES_REJECTED,

    FETCH_EMPLOYEE_PENDING,
    FETCH_EMPLOYEE_FULFILLED,
    FETCH_EMPLOYEE_REJECTED,

    CREATE_EMPLOYEE_PENDING,
    CREATE_EMPLOYEE_FULFILLED,
    CREATE_EMPLOYEE_REJECTED,

    UPDATE_EMPLOYEE_PENDING,
    UPDATE_EMPLOYEE_FULFILLED,
    UPDATE_EMPLOYEE_REJECTED,

    DELETE_EMPLOYEE_PENDING,
    DELETE_EMPLOYEE_FULFILLED,
    DELETE_EMPLOYEE_REJECTED,
    
    CHANGE_EMPLOYEE,
    
    ADD_EMPLOYEE

} from './employeeActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfEmployees: [],
    employee: {
        codigo: 0,
        nome: '',
        nascimento: '',  
        mae: '',
        cpf: '',
        rg: '',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        cidade: '',
        estado: '',
        status: '',
        telefone: '',
        celular: '',
        email: '',
        dataCadastro: '',
        usuarioCadastro: 0
    },
    error: null,
}

export function employeeReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EMPLOYEE: 
            let value = ""
            
            if (action.payload.name.includes('email')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                employee: { ...state.employee, 
                    [action.payload.name]: value
                }
            })
        case ADD_EMPLOYEE: 
            return Object.assign({}, state, {
                employee: { ...initialState.employee }
            })
        case FETCH_EMPLOYEES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEmployees: [],
            })
        case FETCH_EMPLOYEES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfEmployees: action.payload
            })
        case FETCH_EMPLOYEES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_EMPLOYEE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_EMPLOYEE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                employee: action.payload
            })
        case CREATE_EMPLOYEE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_EMPLOYEE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_EMPLOYEE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                employee: action.payload
            })
        case UPDATE_EMPLOYEE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_EMPLOYEE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_EMPLOYEE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                employee: action.payload
            })
        case FETCH_EMPLOYEE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_EMPLOYEE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EMPLOYEE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EMPLOYEE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}