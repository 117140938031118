import api from '../../../../api'

export const FETCH_EMPLOYEES_PENDING = 'FETCH_EMPLOYEES_PENDING'
export const FETCH_EMPLOYEES_FULFILLED = 'FETCH_EMPLOYEES_FULFILLED'
export const FETCH_EMPLOYEES_REJECTED = 'FETCH_EMPLOYEES_REJECTED'

export const FETCH_EMPLOYEE_PENDING = 'FETCH_EMPLOYEE_PENDING'
export const FETCH_EMPLOYEE_FULFILLED = 'FETCH_EMPLOYEE_FULFILLED'
export const FETCH_EMPLOYEE_REJECTED = 'FETCH_EMPLOYEE_REJECTED'

export const CREATE_EMPLOYEE_PENDING = 'CREATE_EMPLOYEE_PENDING'
export const CREATE_EMPLOYEE_FULFILLED = 'CREATE_EMPLOYEE_FULFILLED'
export const CREATE_EMPLOYEE_REJECTED = 'CREATE_EMPLOYEE_REJECTED'

export const UPDATE_EMPLOYEE_PENDING = 'UPDATE_EMPLOYEE_PENDING'
export const UPDATE_EMPLOYEE_FULFILLED = 'UPDATE_EMPLOYEE_FULFILLED'
export const UPDATE_EMPLOYEE_REJECTED = 'UPDATE_EMPLOYEE_REJECTED'

export const DELETE_EMPLOYEE_PENDING = 'DELETE_EMPLOYEE_PENDING'
export const DELETE_EMPLOYEE_FULFILLED = 'DELETE_EMPLOYEE_FULFILLED'
export const DELETE_EMPLOYEE_REJECTED = 'DELETE_EMPLOYEE_REJECTED'

export const CHANGE_EMPLOYEE = 'CHANGE_EMPLOYEE'

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'

export function changeEmployee(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_EMPLOYEE, payload: { name, value } })
  }
}

export function addEmployee() {
  return dispatch => {
    dispatch({ type: ADD_EMPLOYEE });
  };
}

export function findAllEmployees(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EMPLOYEES_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
                query findAllEmployees {
                  funcionarios (first: ${first} offset: ${offset}) {
                    codigo
                    nome
                    cpf
                    nascimento
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EMPLOYEES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { funcionarios } = data
        dispatch({ type: FETCH_EMPLOYEES_FULFILLED, payload: funcionarios });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EMPLOYEES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllEmployeeByName(search = '', first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EMPLOYEES_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findAllEmployeesByName {
                  funcionariosPorNome (search: "%${search}%" first: ${first} offset: ${offset}) {
                    codigo
                    nome
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EMPLOYEES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { funcionariosPorNome } = data
        dispatch({ type: FETCH_EMPLOYEES_FULFILLED, payload: funcionariosPorNome });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EMPLOYEES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findEmployee(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EMPLOYEE_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findEmployee {
                  funcionario (codigo: ${codigo}) {
                    codigo
                    nome
                    nascimento  
                    mae
                    cpf
                    rg
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    cidade
                    estado
                    status
                    telefone
                    celular
                    email
                    dataCadastro
                    usuarioCadastro
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EMPLOYEE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { funcionario } = data
        dispatch({ type: FETCH_EMPLOYEE_FULFILLED, payload: funcionario });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EMPLOYEE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createEmployee(employee) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_EMPLOYEE_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation createEmployee {
            inclusaoFuncionario (input: {
              nome: "${employee.nome}"
              nascimento: "${employee.nascimento}"  
              mae: "${employee.mae}"
              cpf: "${employee.cpf}"
              rg: "${employee.rg}"
              endereco: "${employee.endereco}"
              numero: "${employee.numero}"
              complemento: "${employee.complemento}"
              cep: "${employee.cep}"
              bairro: "${employee.bairro}"
              cidade: "${employee.cidade}"
              estado: "${employee.estado}"
              status: 0,
              telefone: "${employee.telefone}"
              celular: "${employee.celular}"
              email: "${employee.email}"
            } ) {
              codigo
              nome
              nascimento  
              mae
              cpf
              rg
              endereco
              numero
              complemento
              cep
              bairro
              cidade
              estado
              status
              telefone
              celular
              email
              dataCadastro
              usuarioCadastro
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_EMPLOYEE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { inclusaoFuncionario } = data
        dispatch({ type: CREATE_EMPLOYEE_FULFILLED, payload: inclusaoFuncionario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_EMPLOYEE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateEmployee(employee) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_EMPLOYEE_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation UpdateEmployee {
            atualizacaoFuncionario (codigo: ${employee.codigo} input: {
              nome: "${employee.nome}"
              nascimento: "${employee.nascimento}"  
              mae: "${employee.mae}"
              cpf: "${employee.cpf}"
              rg: "${employee.rg}"
              endereco: "${employee.endereco}"
              numero: "${employee.numero}"
              complemento: "${employee.complemento}"
              cep: "${employee.cep}"
              bairro: "${employee.bairro}"
              cidade: "${employee.cidade}"
              estado: "${employee.estado}"
              status: 0
              telefone: "${employee.telefone}"
              celular: "${employee.celular}"
              email: "${employee.email}"
            } ) {
              codigo
              nome
              nascimento  
              mae
              cpf
              rg
              endereco
              numero
              complemento
              cep
              bairro
              cidade
              estado
              status
              telefone
              celular
              email
              dataCadastro
              usuarioCadastro
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_EMPLOYEE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { atualizacaoFuncionario } = data
        dispatch({ type: UPDATE_EMPLOYEE_FULFILLED, payload: atualizacaoFuncionario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_EMPLOYEE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeEmployee(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_EMPLOYEE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemoveEmployee {
            deleteEmployee (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_EMPLOYEE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteEmployee } = data
        dispatch({ type: DELETE_EMPLOYEE_FULFILLED, payload: deleteEmployee });
        dispatch(findAllEmployees());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_EMPLOYEE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
