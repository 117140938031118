import {

    FETCH_VEHICLES_CUSTOMER_PENDING,
    FETCH_VEHICLES_CUSTOMER_FULFILLED,
    FETCH_VEHICLES_CUSTOMER_REJECTED,

    DELETE_VEHICLES_CUSTOMER_PENDING,
    DELETE_VEHICLES_CUSTOMER_FULFILLED,
    DELETE_VEHICLES_CUSTOMER_REJECTED,

    CHANGE_VEHICLES_CUSTOMER

} from './vehiclesCustomerActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfVehiclesCustomer: [],
    vehiclesCustomer: {
        veiculo: 0,
        velocidadeTipo: 0,
        velocidadeValor: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss"),
        dataHoraInicial: moment().utc().format("YYYY-MM-DD") + ' 00:00:00',
        dataHoraFinal: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    error: null,
}

export function vehiclesCustomerReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_VEHICLES_CUSTOMER:
            return Object.assign({}, state, {
                vehiclesCustomer: {
                    ...state.vehiclesCustomer,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_VEHICLES_CUSTOMER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfVehiclesCustomer: [],
            })
        case FETCH_VEHICLES_CUSTOMER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfVehiclesCustomer: action.payload
            })
        case FETCH_VEHICLES_CUSTOMER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfVehiclesCustomer: [],
                error: action.payload
            })

        case DELETE_VEHICLES_CUSTOMER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_VEHICLES_CUSTOMER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_VEHICLES_CUSTOMER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}
